import React from 'react'
import ReactDOM from 'react-dom'
import Conversation from './Conversation'

document.addEventListener('turbo:load', () => {
  const conversationContainer = document.getElementById('conversationContainer')
  const conversation = window.conversation
  const can_update_conversation = window.can_update_conversation
  const current_user_id = window.current_user_id
  
  if (conversation && conversationContainer) {
    ReactDOM.render(
      <Conversation 
        conversation={conversation} 
        can_update_conversation={can_update_conversation} 
        current_user_id={current_user_id}
      />,
      conversationContainer,
    );
    
    document.addEventListener('turbo:before-visit', () => {
      ReactDOM.unmountComponentAtNode(conversationContainer)
    })
  }  
})