import React, { Component } from 'react'
import ActionCable  from 'actioncable'
import { client } from './../Client'
import ConversationMessages from './ConversationMessages'
import ConversationMessageForm from './ConversationMessageForm'
import { toastrNotification } from '../../helpers/Toastr'

class Conversation extends Component {  
  
  state = {
    subscriptionActive: false,
    search: ""
  }
  
  subscription = {}
  channelName = "ConversationChannel"
  
  componentDidMount() { 
    $('[data-toggle="tooltip"]').tooltip()
       
    let _this = this;
    
    let listener = {
      received(data) {
        switch(data.action) {
        case 'create_message':
          _this.addNewMessage(data)
          break
        case 'delete_message':
          _this.deleteMessage(data)
          break  
        }
        //console.log("received, channel:" + _this.channelName, data)
      }
    }

    ActionCable.debugging = true
    window.cable = ActionCable.createConsumer("/cable")
    this.subscription = window.cable.subscriptions.create({ channel: this.channelName, conversation_id: this.props.conversation.id }, listener)
    if ( this.subscription ) { this.setState({ subscriptionActive: true }) }
  }
  
  componentWillUnmount() {
    if (this.subscription) {
      setTimeout(() => {console.log('unsubscribed'); this.subscription.unsubscribe()}, 100);
    }
  }
  
  addNewMessage(data) {
    if (data.success == true) {
      const messageData = JSON.parse(data.message)
      this.refs.messagesList.addMessage(messageData)
      if (this.refs.messageForm !== undefined && data.author_id === this.props.current_user_id ) { 
        this.refs.messageForm.reset() 
      }
    } else {
      if ( data.author_id === this.props.current_user_id ) {
        toastrNotification({ type: 'success', message: data.errors })
      }
    }
  }
  
  deleteMessage(data) {
    this.refs.messagesList.deleteMessageFromList(data.message_id)
  }
  
  onSearchChange = (evt) => {
    this.setState({ search: evt.target.value })
  } 
  
  showMembersList = (e) => {
    e.preventDefault()
    $('#showMemberList').toggleClass('active')
    $('body').toggleClass('show-sidebar-right')
    
    if ( $('.chat-sidebar-right').length > 0 ) {
      new PerfectScrollbar('.chat-sidebar-right', {
        suppressScrollX: true
      })
    }
  }
  
  render() {
    
    const { conversation, can_update_conversation, current_user_id } = this.props
                
    return (
      <>
        <div className="chat-content-header">
          <h6 id="channelTitle" className="mg-b-0">
            { conversation.name }
          </h6>
          <div className="d-flex">
            { conversation.convo_mode == "group" && 
              <nav id="channelNav">
                <span id="favoriteLink">
                  <a href={ Routes.toggle_favorite_conversation_path(conversation.id) } data-method="put" data-remote="true" data-toggle="tooltip" title={ conversation.favorited_by.includes(current_user_id) ? window.t('chat.conversation.remove_from_favorites') : window.t('chat.conversation.add_to_favorites') }>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill={conversation.favorited_by.includes(current_user_id) ? '#ffc107' : 'none'} stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-star"><polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon></svg>
                  </a>
                </span>
                { !conversation.support && can_update_conversation &&
                <a  href={ Routes.candidates_conversation_participants_path(conversation.id) } data-modal="true" >
                  <span data-toggle="tooltip" title={ window.t('chat.conversation.add_participants') }>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-user-plus"><path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="8.5" cy="7" r="4"></circle><line x1="20" y1="8" x2="20" y2="14"></line><line x1="23" y1="11" x2="17" y2="11"></line></svg>
                  </span>
                </a>
                }
                { !conversation.support && can_update_conversation &&
                <a id="showMemberList" onClick={this.showMembersList} href="#" data-toggle="tooltip" title={ window.t('chat.conversation.participants_list') } className="d-flex align-items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-users"><path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path></svg>
                  <span className="tx-medium mg-l-5">
                    { conversation.participants_size }
                  </span>
                </a>
                }
                { !conversation.support && can_update_conversation &&
                <a href={ Routes.edit_conversation_path(conversation.id) } data-modal="true" >
                  <span data-toggle="tooltip" title={ window.t("edit") }>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-edit"><path d="M20 14.66V20a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h5.34"></path><polygon points="18 2 22 6 12 16 8 16 8 12 18 2"></polygon></svg>
                  </span>
                </a>
                }
                <a href={ Routes.open_delete_modal_conversation_path(conversation.id) } data-modal="true" >
                  <span data-toggle="tooltip" title={ window.t("delete") }>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-delete"><path d="M21 4H8l-7 8 7 8h13a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2z"></path><line x1="18" y1="9" x2="12" y2="15"></line><line x1="12" y1="9" x2="18" y2="15"></line></svg>
                  </span>
                </a>
              </nav>
             }
             { conversation.convo_mode == "direct" && 
                <nav id="directNav">
                  <span id="favoriteLink">
                    <a href={ Routes.toggle_favorite_conversation_path(conversation.id) } data-method="put" data-remote="true" data-toggle="tooltip" title={ conversation.favorited_by.includes(current_user_id) ? window.t('chat.conversation.remove_from_favorites') : window.t('chat.conversation.add_to_favorites') }>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill={conversation.favorited_by.includes(current_user_id) ? '#ffc107' : 'none'} stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-star"><polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon></svg>
                    </a>
                  </span>
                  <a href={ Routes.open_delete_modal_conversation_path(conversation.id) } data-modal="true" >
                    <span data-toggle="tooltip" title={ window.t("delete") }>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-delete"><path d="M21 4H8l-7 8 7 8h13a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2z"></path><line x1="18" y1="9" x2="12" y2="15"></line><line x1="12" y1="9" x2="18" y2="15"></line></svg>
                    </span>
                  </a>
                </nav>
             }
             <div className="search-form mg-l-15 d-none d-sm-flex">
               <input type="search" onChange={this.onSearchChange} className="form-control" placeholder={ window.t('search') } />
               <button className="btn" type="button"><i data-feather="search"></i></button>
             </div> 
          </div>
        </div>

        <ConversationMessages 
          subscription={ this.subscription }  
          conversation={ conversation }
          current_user_id={ current_user_id }
          can_update_conversation={ can_update_conversation }
          search={ this.state.search }
          ref="messagesList" 
        />

        <div className="chat-sidebar-right">
          <div className="pd-y-20 pd-x-10">
            <div className="chat-member-list">
              { conversation.groups.length > 0 && 
                <div>
                  <div className="tx-10 tx-uppercase tx-medium tx-color-03 tx-sans tx-spacing-1 pd-l-10">{ window.t('chat.conversation.groups_list') }</div>
                  { conversation.groups.map(group => (
                    <div className="media" key={"group" + group.id}>
                      <div className="media-body">
                        <h6 className="mg-b-0">
                          { group.name }
                          <a href={ Routes.conversation_group_path(conversation.id, group.id) } data-method="delete" >
                            <span data-toggle="tooltip" title={ window.t("delete") } >
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-delete"><path d="M21 4H8l-7 8 7 8h13a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2z"></path><line x1="18" y1="9" x2="12" y2="15"></line><line x1="12" y1="9" x2="18" y2="15"></line></svg>
                            </span>
                          </a>  
                        </h6>
                      </div>
                    </div>
                  ))}
                  <div className="mb-2"></div>
                </div>
              }
              { conversation.participants.length > 0 &&
                <div>
                  <div className="tx-10 tx-uppercase tx-medium tx-color-03 tx-sans tx-spacing-1 pd-l-10">{ window.t('chat.conversation.participants_list') }</div>
                  { conversation.participants.filter(p => this.props.current_user_id !== p.id).map(participant => (
                    <div className="media" key={"participant" + participant.id}>
                      { !participant.avatar_present && 
                        <div className="avatar avatar-xs">
                          <span className="avatar-initial rounded-circle">{ participant.first_name.charAt(0).toUpperCase() }</span>
                        </div>
                      }
                      { participant.avatar_present &&
                        <div className="avatar avatar-xs">
                          <img src={ participant.avatar_url } className="rounded-circle" />
                        </div>
                      }
                      <div className="media-body mg-l-10">
                        <h6 className="mg-b-0">
                          { participant.full_name }
                          <a href={ Routes.conversation_participant_path(conversation.id, participant.id) } data-method="delete" >
                            <span data-toggle="tooltip" title={ window.t("delete") } >
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-delete"><path d="M21 4H8l-7 8 7 8h13a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2z"></path><line x1="18" y1="9" x2="12" y2="15"></line><line x1="12" y1="9" x2="18" y2="15"></line></svg>
                            </span>
                          </a>
                        </h6>
                      </div>
                    </div>
                  ))}
                </div>
              }    
            </div>
          </div>
        </div>

        { this.state.subscriptionActive && 
          <ConversationMessageForm 
            subscription={this.subscription} 
            conversation={this.props.conversation}  
            ref="messageForm" 
          />
        }  
      </>
    )
  }
  
}

export default Conversation
