import React, { useState } from 'react'
import QuestionForm from './QuestionForm'
import { client } from '../../Client'
import { toastrNotification } from '../../../helpers/Toastr'

export default function SurveyQuestion({
  survey,
  question,
  setQuestion,
  removeQuestion,
  moveQuestionUp,
  moveQuestionDown
}) {
  const [prevQuestion, setPrevQuestion] = useState(question)
  const [editing, setEditing] = useState(!question.persisted)
  const [submitting, setSubmitting] = useState(false)
    
  function toggleEditing() {
    setEditing(!editing)
  }
  
  function saveQuestion() {
    const data = { name: question.text,
                   qtype: question.type,
                   required: question.required,
                   options: question.options }
                   
    if (question.hasOptions && question.options.length === 0) {
      toastrNotification({ type: 'warning', title:  window.t('survey.enter_options') })
      return
    }               
    
    setSubmitting(true)               
    
    if (question.persisted) {
      client.updateSurveyQuestion(survey.id, question.id, data)
      .then(response => {
        if (response.id) {
          setEditing(false)
        } else {
          toastrNotification({ type: 'error', title:  window.t('not_updated') })  
        }
        setSubmitting(false)
      }) 
    } else {
      client.createSurveyQuestion(survey.id, data)
      .then(response => {
        if (response.id) {
          setEditing(false)
        } else {
          toastrNotification({ type: 'error', title:  window.t('not_updated') })  
        }
        setSubmitting(false) 
      })  
    }
  }
  
  function handleCancelChanges() {
    setQuestion(question.merge({ text: prevQuestion.text,
                                 type: prevQuestion.type,
                                 options: prevQuestion.options,
                                 required: prevQuestion.required
                               })
               )
    setEditing(false)              
  }
  
  function handleQuestionRemove() {
    if (!question.persisted) {
      removeQuestion()
      return
    }
    
    client.deleteSurveyQuestion(survey.id, question.id)
    .then(response => {
      removeQuestion()
    })
  }

  return (
    <div className="card mg-b-15">
      <div className="card-body">
        {editing ? (
          <QuestionForm question={question} setQuestion={setQuestion} />
        ) : (
          <>
            <p>{question.text}</p>
            {question.hasOptions ? (
              question.options.map((option, i) => (
                <label key={i} className="d-block">
                  <input
                    type={question.inputType}
                    id={option}
                    name={option}
                    value={option}
                    disabled
                  />
                  <span className="mg-l-10">
                    { option }
                  </span>
                </label>
              ))
            ) : (
              <textarea disabled className="form-control" />
            )}
            <p className="mg-t-10">{ window.t('survey.required') }: { question.required ? window.t('_yes') : window.t('_no') }</p>
          </>
        )}
        
        <div className="row">
          <div className="col-12 mg-t-20">
            {editing ? (
              <button onClick={e => saveQuestion()} disabled={submitting} className="btn btn-white btn-xs mg-r-5">
                <i className="fas fa-save icon" />
                { window.t('save') }
              </button>
            ) : (
              <button onClick={e => setEditing(true)} className="btn btn-white btn-xs mg-r-5">
                <i className="fas fa-pen icon" />
                { window.t('edit') }
              </button>
            )}
            {editing ? (
              <button onClick={e => handleCancelChanges()} disabled={submitting} className="btn btn-white btn-xs">
                <i className="fas fa-undo icon" />
                { window.t('cancel') }
              </button>
            ) : (
              <button onClick={e => handleQuestionRemove()} disabled={submitting} className="btn btn-white btn-xs">
                <i className="fas fa-trash-alt icon" />
                { window.t('delete') }
              </button>
            )}
          </div>
        </div>
        
        <div className="row">
          <div className="col-12 mg-t-15">
            { window.t('survey.move_question') }:{" "}
            <button onClick={moveQuestionUp} className="btn btn-white mg-r-5 mg-l-5 btn-xs">
              <i className="fas fa-angle-up icon" />
              { window.t('survey.up') }
            </button>
            <button onClick={moveQuestionDown} className="btn btn-white btn-xs">
              <i className="fas fa-angle-down icon" />
              { window.t('survey.down') }
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
