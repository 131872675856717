import React from 'react'
import { Link } from 'react-router-dom'

const CourseCreatorNav = (props) => {
  const { course, currentForm, locale } = props

  return (
    <ul className="nav nav-pills nav-fill">
      <li className="nav-item">
        <Link to={Routes.tutor_course_path(course.id, {locale: locale})} className={"nav-link " + (currentForm == 'details' ? 'active' : '')}>
          { window.t('course.details')}
        </Link>
      </li>
      { course.package
        ?
        <li className="nav-item">
          <Link to={Routes.tutor_course_package_items_path(course.id, {locale: locale})} className={"nav-link " + (currentForm == 'packageItems' ? 'active' : '')}>
            { window.t('course.package_items')}
          </Link>
        </li>
        :
        <li className="nav-item">
          <Link to={Routes.tutor_course_curriculum_path(course.id, {locale: locale})} className={"nav-link " + (currentForm == 'curriculum' ? 'active' : '')}>
            { window.t('course.curriculum')}
          </Link>
        </li>
      }
      <li className="nav-item">
        <Link to={Routes.tutor_course_price_path(course.id, {locale: locale})} className={"nav-link " + (currentForm == 'price' ? 'active' : '')}>
          { window.t('course.price')}
        </Link>
      </li>
      <li className="nav-item">
        <Link to={Routes.tutor_course_publication_path(course.id, {locale: locale})} className={"nav-link " + (currentForm == 'publication' ? 'active' : '')}>
          { window.t('course.publication')}
        </Link>
      </li>
    </ul>
  )
}

export default CourseCreatorNav