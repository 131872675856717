import React from 'react'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import SurveyName from './SurveyName'
import { client } from '../../Client'
import { toastrNotification } from '../../../helpers/Toastr'
  
const SurveyCreatorHeader = (props) => {
  const { survey, locale, pathname, updateSurveyName, course } = props
    
  return (
    <div>
      <div className="d-md-flex d-block align-items-center justify-content-between mg-b-10">
        <div>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb breadcrumb-style1 pt-0 mg-b-10">
              <li className="breadcrumb-item"><a href={ Routes.tutor_course_curriculum_path(locale, course.id) }>{ course.title }</a></li>
              <li className="breadcrumb-item active" aria-current="page">{ survey.name }</li>
            </ol>
          </nav>
          <div className="d-flex">
            <SurveyName survey={survey} updateSurveyName={updateSurveyName} />
          </div>
        </div>
        <div className="d-flex mg-t-10 mg-md-t-0">
          { survey.lection_id && !pathname.includes('/report') &&
            <a className="btn btn-sm btn-white d-flex align-items-center" href={ Routes.tutor_course_lection_path(locale, course.slug, survey.lection_id) } target="_blank" >
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-eye"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path><circle cx="12" cy="12" r="3"></circle></svg>
              &#160;{ window.t('course.preview') }
            </a>
          }
          { pathname.includes('/report') && 
            <a className="btn btn-sm btn-white d-flex align-items-center" href={ Routes.export_answers_tutor_course_survey_path(locale, course.id, survey.id, {format: 'xlsx'}) } >
              <i className="fas fa-download mg-r-5"></i>  { window.t('survey.export_to_excel') }
            </a>
          }
        </div>      
      </div>
      <div className="row">
        <div className="col-md-12 mg-b-40">
          <ul className="nav nav-line nav-line-profile d-flex justify-content-center">
            <li className="nav-item">
              <Link className={ pathname.includes('/report') ? 'nav-link' : 'nav-link active' } to={ Routes.tutor_course_survey_path(locale, course.id, survey.id) }>
                { window.t('survey.editor') }
              </Link>
            </li>
            <li className="nav-item">
              <Link className={ pathname.includes('/report') ? 'nav-link active' : 'nav-link' } aria-current="page" to={ Routes.report_tutor_course_survey_path(locale, course.id, survey.id) } >
                { window.t('survey.report') }
              </Link>       
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default SurveyCreatorHeader