import React from 'react'
import ReactDOM from 'react-dom'
import SurveyCreator from './SurveyCreator'
import { BrowserRouter as Router, Route } from 'react-router-dom'

document.addEventListener('turbo:load', () => {
  const surveyContainer = document.getElementById('surveyCreator')
  
  if (surveyContainer) {
    const survey = JSON.parse(surveyContainer.dataset.survey),
          course = JSON.parse(surveyContainer.dataset.course)
    
    ReactDOM.render(
      <Router>
        <Route 
          path="/:locale/tutor/courses/:courseId/surveys/:surveyId"
          render={(props) => <SurveyCreator {...props} survey={survey} course={course} />} 
        />
      </Router>,
      surveyContainer,
    );
    
    //document.addEventListener('turbo:before-visit', () => {
    //  ReactDOM.unmountComponentAtNode(surveyContainer)
    //})      
  }
  
})