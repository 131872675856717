import React, { useState } from 'react'
import { useInputValue } from '../hooks'
import { client } from '../../Client'
import { toastrNotification } from '../../../helpers/Toastr'

export default function SurveyName({ survey, updateSurveyName }) {
  const [name, setName] = useInputValue(survey.name)
  const [editing, setEditing] = useState(false)
  
  function saveName() {
    client.updateSurvey(survey.id, {name: name})
    .then(response => {
      if (response.id) {
        updateSurveyName(name)
        setEditing(false)
        toastrNotification({ type: 'success', title: window.t('updated') })
      } else {
        toastrNotification({ type: 'error', title:  window.t('not_updated') })
      }
    })
  }

  return (
    <div className="row">
      <div className="col-12 mg-b-15">
        <div className="d-flex">
          <div>
            {editing ? (
              <input type="text" value={name} onChange={setName} className="form-control" />
            ) : (
              <h4>{ survey.name }</h4>
            )}
          </div>
          <div>
            {editing ? (
              <button onClick={e => saveName()} className="btn btn-white btn-xs mg-l-10">
                <i className="fas fa-save icon" />
                { window.t('save') }
              </button>
            ) : (
              <button onClick={e => setEditing(true)} className="btn btn-white btn-xs mg-l-10">
                <i className="fas fa-pen icon" />
                { window.t('edit') }
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
