import React from 'react'
import ReactDOM from 'react-dom'
import Notifications from './Notifications'

document.addEventListener('turbo:load', () => {
  const notificationsCenterContainer = document.getElementById('notificationsCenter'),
        current_user_id = window.current_user_id
        
  if (notificationsCenterContainer) {
    ReactDOM.render(
      <Notifications 
        current_user_id={current_user_id}
      />,
      notificationsCenterContainer,
    );
    
    document.addEventListener('turbo:before-visit', () => {
      ReactDOM.unmountComponentAtNode(notificationsCenterContainer)
    })
  }
})
