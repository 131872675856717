import React, { useState } from 'react'
import { Tabs, Tab } from 'react-bootstrap'
import CourseSection from './CourseSection'
import DescriptionTab from './DescriptionTab'

const CourseTabs = (props) => {
  const activeTab = (window.innerWidth <= 575 || props.fullWidth) ? 'modules' : 'description'  
  const [key, setKey] = useState(activeTab),
        { course, currentLection, locale, fullWidth, loadLection, trackers,
          showFinishCourseMessage, finishCourseMessageIsActive, progress} = props

  return (
    <Tabs
      id="courseTabs"
      activeKey={key}
      onSelect={(k) => setKey(k)}
    >
      { (fullWidth || window.innerWidth <= 575) && 
        <Tab eventKey="modules" title={window.t('course_viewer.table_of_content')}>
          <div className="row">
            <div className="col-12 col-md-8 offset-md-2">
              <div className="lections-section">
                { course.sections.map((section, index) => (
                  <CourseSection 
                    section={section} 
                    course={course} 
                    index={index}
                    locale={locale} 
                    currentLection={currentLection}
                    loadLection={loadLection}
                    trackers={trackers}
                    isOpened={section.lections.findIndex(l => l.id === currentLection.id) !== -1}
                    showFinishCourseLink={index === course.sections.length - 1 && progress === 100}
                    showFinishCourseMessage={showFinishCourseMessage}
                    finishCourseMessageIsActive={finishCourseMessageIsActive}
                    key={'courseSection' + section.id} 
                  />
                ))}
              </div>
            </div>
          </div>
        </Tab>
      }
      <Tab eventKey="description" title={ window.t('course_viewer.course_description') }>
        <div>
          <DescriptionTab 
            course={course} 
            currentLection={currentLection}
          />
        </div>
      </Tab>
    </Tabs>
  )
}

export default CourseTabs