import React, { useState, useEffect } from 'react'
import SurveyQuestion from './SurveyQuestion'
import Question from '../models/Question'
import ListController from '../controllers/ListController'
import { client } from '../../Client'

import '../styles.css'

export default function SurveyBuilder({ survey }) {
  const surveyQuestions = survey.questions.map(question => {
    return new Question({
      id: question.id,
      text: question.name,
      type: question.qtype,
      options: question.options,
      required: question.required,
      persisted: true
    })
  })
  const [questions, setQuestions] = useState(surveyQuestions)
  const [sorted, setSorted] = useState(false)
  const listController = new ListController(questions, setQuestions)
  
  function handleQuestionsSort(direction, i) {
    if (direction === 'up') {
      listController.moveUp(i)
    } else {
      listController.moveDown(i)
    }
    setSorted(true)
  }
  
  useEffect(() => {
    if (sorted) {
      const questionIds = questions.map(question => question.id)
      client.sortSurveyQuestions(survey.id, questionIds)
      setSorted(false)  
    }
  })
  
  return (
    <div className="small-container">
      
      { questions.length > 0 ? 
        <div>
          {questions.map((question, i) => (
            <SurveyQuestion
              key={question.id}
              survey={survey}
              question={question}
              setQuestion={question => listController.set(i, question)}
              removeQuestion={() => listController.remove(i)}
              moveQuestionUp={() => handleQuestionsSort('up', i)}
              moveQuestionDown={() => handleQuestionsSort('down', i)}
            />
          ))}
          <button onClick={() => listController.add(new Question())} className="btn btn-white btn-xs mg-t-15">
            <i className="fas fa-plus icon" />
            { window.t('survey.add_question') }
          </button>
        </div> :
        <div className="text-center">
          <div>
            { window.t('survey.no_questions_yet') }
          </div>
          <button onClick={() => listController.add(new Question())} className="btn btn-white btn-xs mg-t-15">
            <i className="fas fa-plus icon" />
            { window.t('survey.add_question') }
          </button>
        </div>
      }  
      
    </div>
  )
}
