import React from 'react'
import { client } from './../Client'
import Loader from '../../helpers/Loader'
import { Modal, Button } from 'react-bootstrap'
import CheckboxTree from 'react-checkbox-tree'
import { toastrNotification } from '../../helpers/Toastr'

import 'react-checkbox-tree/lib/react-checkbox-tree.css'

class GroupFilesTree extends React.Component {
  
  state = {
    open: false,
    checked: [],
    expanded: [],
    nodes: [],
    isLoading: true,
    buttonDisabled: false
  }
  
  componentDidMount() {
    const onlyVideo = (this.props.target === 'coursePromoVideo')
    client.getFileStructure(onlyVideo).
    then((response) => {
      this.setState({ nodes: JSON.parse(response.file_structure), isLoading: false })
    })
  }

  handleOpen = () => {
    this.setState({ open: true, checked: [] }) 
  }

  handleClose = () => {
    this.setState({ open: false }) 
  }
  
  onCheck = (checked) => {
    this.setState({ checked })
  }

  onExpand = (expanded) => {
    this.setState({ expanded })
  }
  
  sendFiles = () => {
    const { target, courseId, sectionId, lectionId } = this.props
        
    if ( target === "group") { 
      this.setState({ buttonDisabled: true })
      client.createGroupFiles(this.state.checked, window.group_id).
      then((response) => {
        Turbo.visit(window.location)
      })
    } else if ( target === "homework" ) {
      this.setState({ buttonDisabled: true })
      client.createHomeworkFiles(this.state.checked, window.homework_id).
      then((response) => {
        Turbo.visit(window.location)
      })
    } else if ( target === "lection" ) {
      const checked = this.state.checked
      
      if (checked.length === 0) {
        toastrNotification({ type: 'warning', title: 'Select a file please'})
        return
      }
      
      if (checked.length > 1) {
        toastrNotification({ type: 'warning', title: window.t('course.only_one_file')})
        return
      }
      
      this.setState({ buttonDisabled: true })
      
      client.addCourseLectionContent(courseId, sectionId, lectionId, this.state.checked[0], 'file')
      .then((lection) => {
        this.setState({ buttonDisabled: false })
        if (lection.id) {
          this.handleClose()
          this.props.onFileAdd(sectionId, lection)
        } else {
          toastrNotification({ type: 'error', title: 'Unable to attach a video' })
        }
      })
    } else if (target === "lection_resource") {
      const checked = this.state.checked
      
      if (checked.length === 0) {
        toastrNotification({ type: 'warning', title: 'Select a file please'})
        return
      }
      
      this.setState({ buttonDisabled: true })
      
      client.addCourseLectionResources(courseId, sectionId, lectionId, this.state.checked)
      .then((lection) => {
        this.setState({ buttonDisabled: false })
        if (lection.id) {
          this.handleClose()
          this.props.onFileAdd(sectionId, lection)
        } else {
          toastrNotification({ type: 'error', title: 'Unable to attach resources' })
        }
      })
    } else if ( target === "coursePromoVideo" ) {
      const checked = this.state.checked
      
      if (checked.length === 0) {
        toastrNotification({ type: 'warning', title: 'Select a file please'})
        return
      }
      
      if (checked.length > 1) {
        toastrNotification({ type: 'warning', title: window.t('course.only_one_file')})
        return
      }
      
      this.setState({ buttonDisabled: true })
      
      client.getFile(this.state.checked[0])
      .then(file => {
        this.setState({ buttonDisabled: false })
        if (file.id) {
          this.props.onFileSelect(file)
          this.handleClose()
        } else {
          toastrNotification({ type: 'error', title: 'Unable to select video file' })
        }
      })
    } else {
      return false
    } 
  }
  
  render () {
    const { checked, expanded, nodes, isLoading, buttonDisabled } = this.state
    const target = this.props.target
    
    return (
      <>
        { target !== 'lection' && target !== 'lection_resource' && target !== 'coursePromoVideo' && 
          <button className="btn btn-sm btn-white btn-block" onClick={() => this.handleOpen()}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-paperclip"><path d="M21.44 11.05l-9.19 9.19a6 6 0 0 1-8.49-8.49l9.19-9.19a4 4 0 0 1 5.66 5.66l-9.2 9.19a2 2 0 0 1-2.83-2.83l8.49-8.48"></path></svg>
            <span className="mg-l-5">
              { window.t("file.add_files") }
            </span>
          </button>
        }    
        <Modal show={this.state.open} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title className="modal-title tx-16">
              { target === 'lection' 
                ? window.t('course.select_lecture_content')
                : window.t('chat.conversation.choose_files') 
              }
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            { this.state.isLoading && 
              <Loader />
            }
            { !this.state.isLoading && nodes.length > 0 &&
              <CheckboxTree
                  checked={checked}
                  expanded={expanded}
                  iconsClass="fa5"
                  noCascade
                  nodes={nodes}
                  onCheck={this.onCheck}
                  onExpand={this.onExpand}
                  onlyLeafCheckboxes={this.props.target === "homework" || this.props.target === "lection"}
              />
            }
            { !this.state.isLoading && nodes.length == 0 &&
              <div className="text-center">
                { window.t('file.no_files_upladed') }
                <div className="mg-t-10">
                  <a href={ Routes.tutor_files_path() } >{ window.t('file.upload_files') }</a>
                </div>
              </div>
            }  
          </Modal.Body>
          <Modal.Footer>
            <a href="#" className="btn btn-link" onClick={() => this.handleClose()}>{ window.t('cancel') }</a>
            <Button className="btn btn-primary ml-auto" disabled={buttonDisabled} onClick={this.sendFiles}>
              { buttonDisabled &&
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              }
              &#160;{ window.t('add') }
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default GroupFilesTree