import React from 'react'

const ContentEmpty = (props) => { 
  return (
    <div className="row">
      <div className="col-12">
        <div className="lection-content-empty text-center">
          { window.t('course_viewer.lection_is_empty') }
        </div>  
      </div>
    </div>
  )
}

export default ContentEmpty