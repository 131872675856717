import React from 'react'
import { client } from '../../Client'
import { Modal, ModalBody, ModalFooter,
         ModalHeader, ModalTitle, Button } from 'react-bootstrap'
import { Formik } from 'formik'
import * as Yup from 'yup'

class CourseSectionForm extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      open: false
    }
  }

  handleOpen = () => {
    this.setState({ open: true })
  }

  handleClose = () => {
    this.setState({ open: false })
  }

  render() {

    const { mode, section, courseId } = this.props

    const validationSchema = Yup.object().shape({
      title: Yup.string()
      .max(60, window.t('form.too_long'))
      .required(window.t('form.required')),
      description: Yup.string()
      .max(200, window.t('form.too_long'))
    })

    return (
        <>
          { mode == "new"
            ?
            <button className="btn btn-sm btn-white" onClick={() => this.handleOpen()}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-plus"><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>
              &#160;{ window.t('course.new_section') }
            </button>
            :
            <button className="btn btn-xs mg-l-5 mg-r-5 btn-white" onClick={() => this.handleOpen()} style={{padding: '3px 7px'}}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-edit"><path d="M20 14.66V20a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h5.34"></path><polygon points="18 2 22 6 12 16 8 16 8 12 18 2"></polygon></svg>
            </button>
          }
          <Modal show={this.state.open} onHide={this.handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>{ mode == 'new' ? window.t('course.new_section') : section.title }</Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <Formik
                 initialValues={{ title: section ? section.title : '',
                                  description: section ? section.description : ''
                               }}
                 validationSchema={validationSchema}
                 onSubmit={(values, { setSubmitting, resetForm }) => {
                   let data = values
                   if (mode == "new") {
                     client.createCourseSection(courseId, values)
                     .then((section) => {
                       if (section.id) {
                         resetForm()
                         this.props.onAddSection(section)
                         this.handleClose()
                       }
                       setSubmitting(false)
                     })
                   } else {
                     client.updateCourseSection(courseId, section.id, values)
                     .then((section) => {
                       if (section.id) {
                         resetForm()
                         this.props.onUpdateSection(section)
                         this.handleClose()
                       }
                       setSubmitting(false)
                     })
                   }
                 }}
               >
                 {({
                   values,
                   errors,
                   touched,
                   handleChange,
                   handleBlur,
                   handleSubmit,
                   isValid,
                   isSubmitting,
                 }) => (
                   <form onSubmit={handleSubmit}>
                     <div className="form-group">
                       <label>{ window.t('course.section_title') }</label>
                       <div className="input-group">
                          <input
                            type="text"
                            name="title"
                            className={"form-control " + (errors.title && touched.title ? 'is-invalid' : '')}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.title}
                            placeholder={ window.t('course.section_title_placeholder') }
                          />
                          <div className="input-group-append">
                            <span className="input-group-text" id="title-addon">{ values.title.length > 60 ? 0 : (60 - values.title.length) }</span>
                          </div>
                          {errors.title && touched.title &&
                            <div className="invalid-feedback">{errors.title}</div>
                          }
                       </div>
                     </div>
                     <div className="form-group">
                       <label>{ window.t('course.section_description') }</label>
                       <textarea
                         type="text"
                         name="description"
                         className={"form-control " + (errors.description && touched.description ? 'is-invalid' : '')}
                         onChange={handleChange}
                         onBlur={handleBlur}
                         value={values.description}
                         placeholder={ window.t('course.section_description_placeholder') }
                       />
                       {errors.description && touched.description &&
                         <div className="invalid-feedback">{errors.description}</div>
                       }
                     </div>
                     <div className="row">
                       <div className="col-12 mg-t-25">
                          <div className="d-flex">
                            <Button variant="link" onClick={() => this.handleClose()}>{ window.t('cancel') }</Button>
                            <Button type="submit" className="btn btn-primary ml-auto" disabled={isSubmitting || !isValid} >
                              { isSubmitting &&
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                              }
                              &#160;{ mode === 'new' ? window.t('add') : window.t('update') }
                            </Button>
                          </div>
                       </div>
                     </div>
                   </form>
                 )}
               </Formik>
            </Modal.Body>
          </Modal>
        </>
      )
  }
}

export default CourseSectionForm