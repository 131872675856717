import React from 'react'
import { client } from '../Client'
import CourseCreatorNav from './CourseCreatorNav'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { toastrNotification } from '../../helpers/Toastr'
  
class Price extends React.Component {
  
  render() {
    const { locale, courseId } = this.props.match.params,
          course = this.props.course
    
    const validationSchema = Yup.object().shape({
      price: Yup.number()
      .required(window.t('form.required'))
      .min(0)
      .integer(),
      discount_price: Yup.number()
      .min(0)
      .integer()
      //.lessThan(Yup.ref('price'), window.t('course.less_then_price'))
    })
    
    return (
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header">
                <CourseCreatorNav 
                  course={course} 
                  currentForm={'price'}
                  locale={locale}
                />
              </div>
              <div className="card-body">
                { !course.ready_for_publication && 
                  <div className="row">
                    <div className="col-12 col-md-6 offset-md-3 text-center text-warning mg-b-15">
                      { window.t('course.contract_is_needed') }
                    </div>
                  </div>
                }
              
                <div className="row">
                  <div className="col-12 offset-md-4 col-md-4 col-sm-6 offset-sm-3"> 
                    
                    <Formik
                      initialValues={{ price: course.price, 
                                       discount_price: course.discount_price }}
                      validationSchema={validationSchema}
                      onSubmit={(values, { setSubmitting }) => {
                        let data = {}
                        data['course_price_attributes'] = values
                        client.updateCourse(course.id, data)
                        .then((course) => {
                          if (course.id) {
                            toastrNotification({ type: 'success', title: window.t('updated') })
                            this.props.onUpdateCourse(course)
                          } else {
                            toastrNotification({ type: 'error', title:  window.t('not_updated') })
                          }
                          setSubmitting(false)
                        })
                      }}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isValid,
                        isSubmitting,
                      }) => (
                        <form onSubmit={handleSubmit}>
                          <div className="form-row">
                            <div className="form-group col-md-12">
                              <label>{ window.t('course.price') }</label>
                              <input
                                type="number"
                                min="0"
                                step="1"
                                name="price"
                                className={"form-control " + (errors.price && touched.price ? 'is-invalid' : '')}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.price}
                                placeholder={ window.t('course.price_placeholder') }
                                disabled={ !course.ready_for_publication }
                              />
                              {errors.price && touched.price && 
                                <div className="invalid-feedback">{errors.price}</div>
                              }
                            </div>
                          </div>
                          
                          <div className="form-group">
                            <label>{ window.t('course.discount_price') }</label>
                            <input
                              type="number"
                              min="0"
                              step="1"
                              name="discount_price"
                              className={"form-control " + (errors.discount_price && touched.discount_price ? 'is-invalid' : '')}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.discount_price}
                              placeholder={ window.t('course.discount_price_placeholder') }
                              disabled={ !course.ready_for_publication }
                            />
                            {errors.discount_price && touched.discount_price && 
                              <div className="invalid-feedback">{errors.discount_price}</div>
                            }
                            <div>
                              { values.discount_price === 0 &&
                                <small>{ window.t('course.discount_price_hint') }</small>
                              }
                              { values.discount_price > 0 && values.price > 0 &&
                                <small>{ window.t('course.has_discount') } <span className="text-danger">{ Math.round((values.price - values.discount_price) / values.price * 100) }%</span></small>
                              }
                            </div>
                          </div>
                          
                          { course.ready_for_publication &&
                            <div className="row">
                              <div className="col-12 text-right">
                                <button type="submit" className="btn btn-primary bnt-lg" disabled={isSubmitting || !isValid}>
                                  { window.t('save') }
                                </button>
                              </div>
                            </div>
                          }    
                        </form>
                      )}
                    </Formik>
                     
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    )
  }
}
 
export default Price