import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "input" ]
  
  connect() {
    const curCountry = this.inputTarget.getAttribute('data-country')
    const inputName = this.inputTarget.getAttribute('data-input')
    const scriptUrl = this.inputTarget.getAttribute('data-script')
    const country = curCountry ? curCountry : 'ru'
    
    window.intlTelInput(this.inputTarget, {
      preferredCountries: ['ru', 'us'],
      autoPlaceholder: 'aggressive',
      formatOnDisplay: true,
      separateDialCode: true,
      initialCountry: country,
      hiddenInput: inputName,
      utilsScript: scriptUrl,
      geoIpLookup: function(success, failure) {
        $.get("https://api.ipdata.co?api-key=e1b3dac13fa0e600f84dcadd1963893ba4cb52af830964b0bc4f2c62", function() {}, "jsonp").always(function(resp) {
          var countryCode = (resp && resp.country_code) ? resp.country_code : "ru";
          success(countryCode);
        });
      },
    })
  }
}