import React, { useRef, useEffect } from 'react'

function useOutsideAlerter(ref, clickOutsideCallback) {
    useEffect(() => {
        function handleClickOutside(event) {
            if (ref && ref.current && !ref.current.contains(event.target)) {
              clickOutsideCallback() 
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside)
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [ref])
}

export default function ClickOutsideTracker(props) {
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, props.handleClickOutside)

    return <div ref={wrapperRef}>{props.children}</div>
}