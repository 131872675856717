import React, { Component } from 'react'
import { client } from './../Client'
import FilesTree from './FilesTree'
import ChatFileUploader from './ChatFileUploader'
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownToggle from 'react-bootstrap/DropdownToggle'
import DropdownItem from 'react-bootstrap/DropdownItem'
import DropdownMenu from 'react-bootstrap/DropdownMenu'
import mediumEditor from 'medium-editor/dist/js/medium-editor'
import 'medium-editor/dist/css/medium-editor.min.css'
import 'medium-editor/dist/css/themes/default.min.css'

import ClickOutsideTracker from '../../helpers/ClickOutsideTracker'

import 'emoji-mart/css/emoji-mart.css'
import { Picker } from 'emoji-mart'

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href=""
    ref={ref} 
    className="chat-plus"
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </a>
))

class ConversationMessageForm extends Component {  
  
  state = {
    minTextLength: 0,
    editor: Object,
    editableContent: null,
    fileUploaderIsOpen: false,
    showEmojiPicker: false
  }
  
  componentDidMount = () => {
    let _this = this;
            
    let editor = new mediumEditor("#conversation-text-editor", {
        placeholder: {
          text: window.t("chat.conversation.write_message"),
          hideOnClick: true
        },
        delay: 1000,
        targetBlank: true,
        toolbar: false,
        autoLink: true
    })
    
    document.addEventListener('keydown', this.keydownHandler)
    
    this.setState({ editor: editor })
  }
  
  keydownHandler = (e) => {
    if (e.keyCode===13 && e.ctrlKey) this.sendMessage()
  }

  componentWillUnmount() {
   document.removeEventListener('keydown', this.keydownHandler)
  }
          
  getContent() {
    return this.state.editor.getContent(0)
  }

  getSerializedContent() {
    return this.state.editor.serialize()
  }

  isValid() {
    let content = this.state.editor.getContent(0)
    if (content === "<p><br></p>") return false
    return content.length > this.state.minTextLength
  }

  reset() {
    return this.state.editor.setContent('', 0);
  }
  
  addEmoji = (emoji, event) => {
    if (!emoji) return false;
    //const imageHtml = `<img class='emojione' alt='${emoji.colons}' title='${emoji.colons}' src='https://cdn.jsdelivr.net/emojione/assets/4.0/png/32/${emoji.unified.split('-')[0]}.png'>`
    const imageHtml = emoji.native
    if (this.state.selectionRange) {
      mediumEditor.selection.selectRange(document, this.state.selectionRange)
    } else {
      this.state.editor.selectElement(document.getElementById("conversation-text-editor"));
      mediumEditor.selection.clearSelection(document, false)
    }
    this.state.editor.pasteHTML(imageHtml, { cleanAttrs: [] });
  }

  toggleEmojiPicker = (event) => {
    event.preventDefault()
    this.focus()
    this.setState({ showEmojiPicker: !this.state.showEmojiPicker })
  }

  closeEmojiPicker = () => {
    this.setState({ showEmojiPicker: false });
  }
  
  //loadContent = (content) => {
  //  this.setState({ editableContent: content })
  //}
  
  focus() {
    const element = document.getElementById('conversation-text-editor');
    element.focus();
  }
  
  sendMessage = (ev) => {
    if ( ev ) { ev.preventDefault() }
    if ( this.isValid() ) {
      this.props.subscription.perform('create_conversation_message', { message: this.getContent() });
    } else {
      this.focus();
      return false
    }
  }
  
  openFilesSelector = () => {
    this.refs.existingFilesSelector.handleOpen()
  }
  
  openFilesUploader = () => {
    this.setState({ fileUploaderIsOpen: true })
  }
  
  closeFilesUploader = () => {
    this.setState({ fileUploaderIsOpen: false })
  }
  
  renderEmojiPicker = () => {
    const footer = document.getElementById('chatFooter')
    const pickerBottom = footer ? footer.getBoundingClientRect().height : 60
    return (
      <div className="conv-chat-emoji-picker d-none d-sm-flex">
        <ClickOutsideTracker handleClickOutside={this.closeEmojiPicker}>
            <Picker
              native={true}
              onClick={this.addEmoji}
              style={{ position: 'absolute', bottom: pickerBottom + 'px', zIndex: "1", display: this.state.showEmojiPicker ? "block" : "none" }}
            />
        </ClickOutsideTracker>
        <a href="" onClick={(event) => this.toggleEmojiPicker(event)} >
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-smile"><circle cx="12" cy="12" r="10"></circle><path d="M8 14s1.5 2 4 2 4-2 4-2"></path><line x1="9" y1="9" x2="9.01" y2="9"></line><line x1="15" y1="9" x2="15.01" y2="9"></line></svg>
        </a>
      </div>
    )
  }

  render() {
        
    return (
      <div className="chat-content-footer" id="chatFooter">
        
        { this.renderEmojiPicker() }
        
        { !window.disable_upload_chat_files && 
        <Dropdown className="d-flex">
          <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-paperclip"><path d="M21.44 11.05l-9.19 9.19a6 6 0 0 1-8.49-8.49l9.19-9.19a4 4 0 0 1 5.66 5.66l-9.2 9.19a2 2 0 0 1-2.83-2.83l8.49-8.48"></path></svg>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            { window.current_user_tutor &&
              <Dropdown.Item eventKey="1" onClick={() => this.openFilesSelector()}>
                {window.t('chat.conversation.choose_existing_files')}
              </Dropdown.Item>
            }  
            <Dropdown.Item eventKey="2" onClick={() => this.openFilesUploader()}>
              {window.t('chat.conversation.upload_new_files')}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
       } 
         
        <div
          id="conversation-text-editor"
          className="conversation-text-editor form-control align-self-center bd-0"
          contentEditable
        />
        <nav>
          <a href="" onClick={(ev) => this.sendMessage(ev)} data-toggle="tooltip" title={ window.t("chat.conversation.send") } >
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-send"><line x1="22" y1="2" x2="11" y2="13"></line><polygon points="22 2 15 22 11 13 2 9 22 2"></polygon></svg>
          </a>
        </nav> 
        
        <FilesTree ref="existingFilesSelector" subscription={this.props.subscription} />
        
        { this.state.fileUploaderIsOpen && 
          <ChatFileUploader 
            ref="filesUploader" 
            open={true} 
            subscription={this.props.subscription}
            handleFileUploaderClose={this.closeFilesUploader}
            conversation={this.props.conversation}
          />
        }  
      </div>
    )
  }
  
}

export default ConversationMessageForm
