import React, { useState, useEffect, useMemo } from 'react'
import { client } from '../Client'
import Loader from '../../helpers/Loader'
import DataTable from 'react-data-table-component'

const columns = [
  {
    name: window.t('course.license.user_name'),
    selector: 'user_name',
    sortable: true,
  },
  {
    name: window.t('course.license.order_date'),
    selector: 'order_date',
    sortable: true
  },
  {
    name: window.t('course.license.group'),
    selector: 'group_name',
    sortable: true
  },
  {
    name: window.t('course.license.expire_date'),
    selector: 'expire_date',
    sortable: true
  },
  {
    name: window.t('course.license.start_date'),
    selector: 'start_date',
    sortable: true
  },
  {
    name: window.t('course.license.progress'),
    selector: 'progress',
    sortable: true
  },
]

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <div className="row">
    <div className="col-12">
      <div className="input-group mb-3">
        <input id="search" type="text" className="form-control" placeholder={window.t('course.filter_by_name')} value={filterText} onChange={onFilter} aria-label="Recipient's username" />
        <div className="input-group-append">
          <button type="button" className="btn btn-primary" onClick={onClear}>
            <i className="fas fa-times"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
)

export default function Users({ course }) {
  const [licenses, setLicenses] = useState([])
  const [fetching, setFetching] = useState(false)
  const [filterText, setFilterText] = useState('')
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false)
  const filteredLicenses = licenses.filter(license => license.user_name && license.user_name.toLowerCase().includes(filterText.toLowerCase()))
  
  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle)
        setFilterText('')
      }
    }

    return <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
  }, [filterText, resetPaginationToggle])
  
  useEffect(() => {
    setFetching(true)
    client.getCourseLicenses(course.id).then(licenses => {
      setLicenses(licenses)
      setFetching(false)  
    })
  }, [])
    
  return (
    <div className="row">
      <div className="col-12">
        { fetching && <Loader /> }
        { !fetching && licenses.length === 0 &&
          <div className="text-center">
            { window.t('course.no_users') }
          </div>
        }
        { !fetching && licenses.length > 0 &&
          <DataTable
            columns={columns}
            data={filteredLicenses}
            responsive={true}
            pagination
            paginationTotalRows={licenses.length}
            paginationResetDefaultPage={resetPaginationToggle}
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
          />
        }
      </div>  
    </div>
  )
}