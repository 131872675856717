import React, { Component } from 'react'
import { client } from './../Client'
import DocViewer, { DocViewerRenderers } from 'react-doc-viewer'
import Loader  from '../../helpers/Loader'

class TransformationBook extends Component { 
  
  constructor(props) {
    super(props)
    
    this.state = {
      book: props.book
    }
  }
  
  componentDidMount() {    
    this.intervalId = setInterval(() => this.getBookStatus(), 3000)
  }
  
  getBookStatus() {
    if (this.state.book.status === 'generating') {
      client.getTransformationBookStatus(this.state.book.id)
      .then((response) => {        
        if (response.status !== 'generating') {
          const book = this.state.book
          book.status = response.status
          this.setState({ book })
        
          if (this.intervalId) { clearInterval(this.intervalId) }
        }
        
      })
    }
  }
  
  render() {
    const { book } = this.state
        
    return (
      <div>
        { book && book.status === 'generating' && 
          <div className="row">
            <div className="col-12 text-center">
              <div className="mg-y-20">{ window.t('transformation_course.book_is_generating') }</div>
              <Loader />
            </div>
          </div>
        }
        
        { book && book.status === 'ready' &&
          <DocViewer 
            pluginRenderers={ DocViewerRenderers } 
            documents={[{ uri: book.url }]}
            style={{minHeight: '400px'}}
            config={{
              header: {
                disableHeader: true,
                disableFileName: true,
                retainURLParams: false
              }
            }}
          />
        }
        
        { book && book.status === 'failed' && 
          <div className="row">
            <div className="col-12 text-center">
              <div className="alert alert-danger">{ window.t('transformation_course.unable_to_generate_the_book') }</div>
            </div>
          </div>
        }
      </div>
    )
  }
}

export default TransformationBook