import React from 'react'
import { client } from '../Client'
import cloneDeep from 'lodash/cloneDeep'
import CourseCreatorNav from './CourseCreatorNav'
import CourseSectionForm from './Curriculum/CourseSectionForm'
import CourseSection from './Curriculum/CourseSection'
import Loader from '../../helpers/Loader'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import arrayMove from 'array-move'


const SortableCourseSection = SortableElement(({section, 
                                                courseId,
                                                onUpdateSection,
                                                onDeleteSection,
                                                onAddLection,
                                                onUpdateLection,
                                                onDeleteLection,
                                                onLectionsSortEnd,
                                                locale
                                              }) => {
  return (
    <CourseSection 
      section={section} 
      key={section.id} 
      courseId={courseId}
      onUpdateSection={onUpdateSection}
      onDeleteSection={onDeleteSection}
      onAddLection={onAddLection} 
      onUpdateLection={onUpdateLection}
      onDeleteLection={onDeleteLection}
      onLectionsSortEnd={onLectionsSortEnd}
      locale={locale}
    />
  )
})

const SortableSectionsList = SortableContainer(({sections,
                                                 courseId,
                                                 onUpdateSection,
                                                 onDeleteSection,
                                                 onAddLection,
                                                 onUpdateLection,
                                                 onDeleteLection,
                                                 onLectionsSortEnd,
                                                 locale
                                               }) => {
  return (
    <div>
      {sections.map((section, index) => (
        <SortableCourseSection 
          section={section} 
          key={`item-${section.id}`}
          index={index} 
          courseId={courseId}
          onUpdateSection={onUpdateSection}
          onDeleteSection={onDeleteSection}
          onAddLection={onAddLection} 
          onUpdateLection={onUpdateLection}
          onDeleteLection={onDeleteLection}
          onLectionsSortEnd={onLectionsSortEnd}
          locale={locale}
        />
      ))}
    </div>
  )
})
  
class Curriculum extends React.Component {
  
  state = {
    sections: [],
    isLoading: false
  }
  
  componentDidMount() {
    const courseId = this.props.match.params.courseId
    
    this.setState({ isLoading: true })
    
    client.getCourseSections(courseId).
    then((sections) => {
      this.setState({ sections: sections, isLoading: false })
    })
  }
  
  addSection = (newSection) => {
    this.setState({ sections: [...this.state.sections, newSection] })
  }
  
  updateSection = (updatedSection) => {
    const sections = [...this.state.sections]
    const newSections = sections.map(section => {
      return section.id == updatedSection.id ? updatedSection : section
    })
    
    this.setState({ sections: newSections })
  }
  
  deleteSection = (deletedSection) => {
    let newSections = [...this.state.sections]
    const index = newSections.findIndex(section => section.id === deletedSection.id)
    if (index !== -1) {
      newSections.splice(index, 1)
      this.setState({ sections: newSections }) 
    }
  }
  
  addLection = (sectionId, newLection) => {
    let newSections = cloneDeep(this.state.sections)
    const index = newSections.findIndex(section => section.id == sectionId)
    if (index !== -1) {
      newSections[index]['lections'] = [...newSections[index]['lections'], newLection]
      this.setState({ sections: newSections })
    }
  }
  
  updateLection = (sectionId, updatedLection) => {
    let newSections = cloneDeep(this.state.sections)
    const index = newSections.findIndex(section => section.id == sectionId)
    if (index !== -1) {
      let newLections = [...newSections[index]['lections']]
      newSections[index]['lections'] = newLections.map(lection => {
        return lection.id == updatedLection.id ? updatedLection : lection
      })
      this.setState({ sections: newSections })
    }
  }
  
  deleteLection = (sectionId, deletedLection) => {
    let newSections = cloneDeep(this.state.sections)
    const index = newSections.findIndex(section => section.id == sectionId)
    
    if (index !== -1) {
      let newLections = [...newSections[index]['lections']]
      const lectionIndex = newLections.findIndex(lection => lection.id == deletedLection.id)
      
      if (lectionIndex !== -1) {
        newLections.splice(lectionIndex, 1)
        newSections[index]['lections'] = newLections
        this.setState({ sections: newSections }) 
      }
    }
  }
  
  onSortEnd = ({oldIndex, newIndex}) => {
    const courseId = this.props.match.params.courseId
    const sortedSections = arrayMove(this.state.sections, oldIndex, newIndex)
    this.setState({ sections: sortedSections })
    const sectionIds = sortedSections.map(section => section.id)
    client.sortSections(courseId, sectionIds)
  }
  
  lectionsSortEnd = (sortedSection, oldIndex, newIndex) => {
    const courseId = this.props.match.params.courseId
    const newSections = this.state.sections.map(section => {
      if (section.id === sortedSection.id) {
        const sortedLections = arrayMove(section['lections'], oldIndex, newIndex)
        section['lections'] = sortedLections
        const lectionIds = sortedLections.map(lection => lection.id) 
        client.sortLections(courseId, sortedSection.id, lectionIds) 
      } 
      return section
    })
    this.setState({ sections: newSections })
  }
  
  render() {
    const { locale, courseId } = this.props.match.params,
          { isLoading, sections } = this.state,
          course = this.props.course
    
    return (
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header">
                <CourseCreatorNav 
                  course={course} 
                  currentForm={'curriculum'}
                  locale={locale}
                />
              </div>
              <div className="card-body">
                { isLoading && 
                  <Loader />
                }
                { !isLoading && 
                  <div className="row">
                    { sections.length > 0 && 
                      <div className="col-12">
                        <SortableSectionsList 
                          sections={sections}
                          courseId={courseId}
                          onUpdateSection={this.updateSection}
                          onDeleteSection={this.deleteSection}
                          onAddLection={this.addLection} 
                          onUpdateLection={this.updateLection}
                          onDeleteLection={this.deleteLection}
                          onLectionsSortEnd={this.lectionsSortEnd}
                          onSortEnd={this.onSortEnd}
                          useDragHandle
                          locale={locale}
                        />
                        
                        <div className="row">
                          <div className="col-md-12 text-right">
                            <CourseSectionForm 
                              courseId={courseId} 
                              onAddSection={this.addSection}
                              mode="new"  
                            />
                          </div>
                        </div>
                      </div>
                    }
                    
                    { sections.length === 0 &&
                      <>
                       <div className="col-12 text-center mg-b-20">
                         { window.t('course.curriculum_intro') }
                       </div>
                       <div className="col-12 text-center">
                          <CourseSectionForm 
                            courseId={courseId} 
                            onAddSection={this.addSection}
                            mode="new"  
                          />
                       </div>  
                      </>
                    }  
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
    )
  }
}
 
export default Curriculum