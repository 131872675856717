import React, { useState } from 'react'
import SurveyBuilder from './components/SurveyBuilder'
import SurveyReport from './components/SurveyReport'
import SurveyCreatorHeader from './components/SurveyCreatorHeader'
import { Route, Switch } from 'react-router-dom'

export default function SurveyCreator(props) {
  const locale = props.match.params.locale
  const pathname = props.location.pathname
  const course = props.course
  const [survey, setSurvey] = useState(props.survey)
        
  function updateSurveyName(newName) {
    setSurvey({ ...survey, name: newName })
  }

  return (
    <>
      <SurveyCreatorHeader 
        survey={survey}
        course={course}
        locale={locale} 
        pathname={pathname}  
        updateSurveyName={updateSurveyName}
      />
      
      <Switch>
        <Route 
          exact 
          path="/:locale/tutor/courses/:courseId/surveys/:surveyId" 
          render={(props) => <SurveyBuilder {...props} survey={survey} course={course} />}
        />
        <Route 
          exact 
          path="/:locale/tutor/courses/:courseId/surveys/:surveyId/report" 
          render={(props) => <SurveyReport {...props} survey={survey} course={course} />}
        />
      </Switch>
    </>  
  )
}