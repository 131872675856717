import React from 'react'
  
const Loader = (props) => {
  return (
      <div className="row">
        <div className="col-12 text-center pt-5 pb-5">
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      </div>
  )
}
 
export default Loader