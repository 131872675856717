import React, { useState, useEffect } from 'react'
import { client } from '../Client'
import CourseCreatorNav from './CourseCreatorNav'
import { Button } from 'react-bootstrap'
import { toastrNotification } from '../../helpers/Toastr'
import Loader from '../../helpers/Loader'
import PackageItemsModal from './PackageItemsModal'
import PackageItemsDeleteModal from './PackageItemsDeleteModal'
import { sortableHandle, SortableContainer, SortableElement } from 'react-sortable-hoc'
import arrayMove from 'array-move'

const DragHandle = sortableHandle(() => (
  <span className="package-item-grag-handle">
    <i className="fas fa-arrows-alt-v"></i>
  </span>
))

const SortablePackageItem = SortableElement(({packageItem}) => {
  return (
    <div className="card mb-3 col-12 package-course-item" key={packageItem.id}>
      <div className="row no-gutters">
        <div className="col-sm-3">
          <img src={packageItem.course.image_url} />
        </div>
        <div className="col-sm-9">
          <div className="card-body">
            <DragHandle />
            <h5 className="text-truncate color-body">
              { packageItem.course.title }
            </h5>
            { packageItem.course.sub_title &&
              <p className="mg-b-0 tx-12 text-truncate tx-color-03" style={{height: "15px"}}>{ packageItem.course.sub_title }</p>
            }
          </div>
        </div>
      </div>
    </div>
  )
})

const SortablePackageItemsList = SortableContainer(({packageItems}) => {
  return (
    <div className="row mg-t-20 no-gutters">
      { packageItems.map((packageItem, index) => (
        <SortablePackageItem
          key={packageItem.id}
          index={index}
          packageItem={packageItem}
        />
      ))}
    </div>
  )
})

export default function PackageItems({course, history, location, match, onUpdateCourse}) {
  const [loading, setLoading] = useState(false)
  const [packageItems, setPackageItems] = useState([])

  useEffect(() => {
    fetchPackageItems()
  }, [])

  const fetchPackageItems = () => {
    setLoading(true)
    client.getCoursePackageItems(course.id).then(items => {
      setPackageItems(items)
      setLoading(false)
    })
  }

  const handleSortEnd = ({oldIndex, newIndex}) => {
    const sortedPackageItems = arrayMove(packageItems, oldIndex, newIndex)
    setPackageItems(sortedPackageItems)
    client.sortPackageItems(course.id, sortedPackageItems.map(pi => pi.id))
  }

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card">
          <div className="card-header">
            <CourseCreatorNav
              course={course}
              currentForm={'packageItems'}
              locale={match.params.locale}
            />
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-12 offset-md-2 col-md-8">
                <div className="d-flex justify-content-end">
                  <PackageItemsModal course={course} />
                  { packageItems.length > 0 &&
                    <PackageItemsDeleteModal course={course} packageItems={packageItems} />
                  }
                </div>

                { loading && <Loader /> }

                { !loading && packageItems.length > 0 &&
                  <SortablePackageItemsList
                    packageItems={packageItems}
                    onSortEnd={handleSortEnd}
                    useDragHandle
                  />
                }

                { !loading && packageItems.length == 0 &&
                  <div className="text-center mg-t-10">
                    { window.t('course.no_available_package_courses') }
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}