import React from 'react'
  
const UserAvatar = (props) => {
  const { user } = props
  return (
      <div className="avatar avatar-sm">
        { user.avatar_present && 
          <img src={ user.avatar_url } className="rounded-circle" alt="" />
        } 
        { !user.avatar_present && 
          <span className="avatar-initial rounded-circle">{ user.first_name[0] }</span>
        }  
      </div>
  )
}
 
export default UserAvatar