import React from 'react'
import { client } from './../Client'
import Loader from '../../helpers/Loader'
import ModalBody from 'react-bootstrap/ModalBody'
import ModalFooter from 'react-bootstrap/ModalFooter'
import ModalHeader from 'react-bootstrap/ModalHeader'
import ModalTitle from 'react-bootstrap/ModalTitle'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import CheckboxTree from 'react-checkbox-tree'

import 'react-checkbox-tree/lib/react-checkbox-tree.css'

class FilesTree extends React.Component {
  
  state = {
    open: false,
    checked: [],
    expanded: [],
    nodes: [],
    isLoading: true
  }
  
  componentDidMount() {
    client.getFileStructure().
    then((response) => {
      this.setState({ nodes: JSON.parse(response.file_structure), isLoading: false })
    })
  }

  handleOpen = () => {
    this.setState({ open: true, checked: [] }) 
  }

  handleClose = () => {
    this.setState({ open: false }) 
  }
  
  onCheck = (checked) => {
    this.setState({ checked })
  }

  onExpand = (expanded) => {
    this.setState({ expanded })
  }
  
  sendFiles = () => {
    this.props.subscription.perform('create_conversation_message', 
                                     { message: "", 
                                       attachments: this.state.checked });
    this.handleClose()
  }
  
  render () {
    const { checked, expanded, nodes, isLoading } = this.state
    
    return (
      <>        
        <Modal show={this.state.open} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{ window.t('chat.conversation.choose_files') }</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            { this.state.isLoading && 
              <Loader />
            }
            { !this.state.isLoading && 
              <CheckboxTree
                  checked={checked}
                  expanded={expanded}
                  iconsClass="fa5"
                  noCascade
                  nodes={nodes}
                  onCheck={this.onCheck}
                  onExpand={this.onExpand}
                  onlyLeafCheckboxes={true}
              />
            }  
          </Modal.Body>
          <Modal.Footer>
            <a href="#" className="btn btn-link" onClick={() => this.handleClose()}>{ window.t('cancel') }</a>
            <Button className="btn btn-primary ml-auto" onClick={this.sendFiles}>
              { window.t('add') }
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default FilesTree