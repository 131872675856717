import React from 'react'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import { client } from '../Client'
import { toastrNotification } from '../../helpers/Toastr'

const courseBadge = (status) => {
  switch (status) {
    case 'internal':
      return <span className="badge bg-primary text-white mg-l-10" style={{alignSelf: 'baseline'}}>{ window.t('course.internal') }</span>
    case 'for_sale':
      return <span className="badge bg-success text-white mg-l-10" style={{alignSelf: 'baseline'}}>{ window.t('course.for_sale') }</span>
    default:
      return null
  }
}

const showDeleteCourseConfirmation = (event, course, locale) => {
  event.preventDefault()
  Swal.fire({
    title: window.t('course.delete_course_confirm'),
    text: course.title,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: window.t('_yes'),
    cancelButtonText: window.t('_no')
  }).then((result) => {
    if (result.value) {
      client.deleteCourse(course.id)
      .then((result) => {
        if ( result.success ) {
          window.location = Routes.tutor_courses_path(locale)
        } else {
          toastrNotification({ type: 'error', title:  window.t('course.unable_to_delete') })
        }
      })
    }
  })
}

const CourseCreatorHeader = (props) => {
  const { course, locale, pathname } = props

  return (
    <div>
      <div className="d-md-flex d-block align-items-center justify-content-between mg-b-10">
        <div>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb breadcrumb-style1 pt-0 mg-b-10">
              <li className="breadcrumb-item"><a href={ Routes.tutor_courses_path(locale) }>{ window.t('course.courses') }</a></li>
              <li className="breadcrumb-item active" aria-current="page">{ course.title }</li>
            </ol>
          </nav>
          <div className="d-flex">
            <h4>{ course.title }</h4>
            { courseBadge(course.status) }
          </div>
        </div>
        <div className="d-flex mg-t-10 mg-md-t-0">
          { course.show_preview_btn && !pathname.includes('/users') && course.lections.length > 0 && !course.package &&
            <a className="btn btn-sm btn-white d-flex align-items-center" href={ Routes.preview_tutor_course_path(locale, course.slug) } >
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-eye"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path><circle cx="12" cy="12" r="3"></circle></svg>
              &#160;{ window.t('course.preview') }
            </a>
          }
          { course.show_preview_btn && !pathname.includes('/users') && course.package &&
            <a className="btn btn-sm btn-white d-flex align-items-center" href={ Routes.preview_package_tutor_course_path(locale, course.slug) } >
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-eye"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path><circle cx="12" cy="12" r="3"></circle></svg>
              &#160;{ window.t('course.preview') }
            </a>
          }
          { course.show_delete_btn && !pathname.includes('/users') &&
            <a className="btn btn-sm btn-white d-flex align-items-center mg-l-5" href="#" onClick={(event) => showDeleteCourseConfirmation(event, course, locale)} >
              <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="css-i6dzq1"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>
              &#160;{ window.t('course.delete') }
            </a>
          }
          { pathname.includes('/users') &&
            <a className="btn btn-sm btn-white d-flex align-items-center" href={ Routes.export_users_tutor_course_path(locale, course.id, {format: 'xlsx'}) } >
              <i className="fas fa-download mg-r-5"></i>  { window.t('survey.export_to_excel') }
            </a>
          }
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 mg-b-40">
          <ul className="nav nav-line nav-line-profile d-flex justify-content-center">
            <li className="nav-item">
              <Link className={ pathname.includes('/users') ? 'nav-link' : 'nav-link active' } to={ Routes.tutor_course_path(course.id, {locale: locale}) }>
                { window.t('course.editor') }
              </Link>
            </li>
            <li className="nav-item">
              <Link className={ pathname.includes('/users') ? 'nav-link active' : 'nav-link' } aria-current="page" to={ Routes.tutor_course_users_path(course.id, {locale: locale}) } >
                { window.t('course.users') }
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default CourseCreatorHeader