import React from 'react'
import ReactDOM from 'react-dom'
import TransformationBook from './TransformationBook'

document.addEventListener('turbo:load', () => {
  const bookElement = document.getElementById('transformationBook')
  
  if (bookElement) {
    const book = JSON.parse(bookElement.dataset.book)
    ReactDOM.render(
      <TransformationBook book={ book } />,
      document.getElementById('transformationBook'),
    )
  }
  
})