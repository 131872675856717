import React from 'react'

const ContentRecording = ({ lection }) => { 
  return (
    <div className="row">
      <div className="col-12">
        <iframe src={lection.content.url} frameBorder="0" style={{overflow: 'hidden', height: '100vh', width: '100%'}}></iframe> 
      </div>
    </div>
  )
}

export default ContentRecording