import React, { useState, useEffect } from 'react'
import { Card, Dropdown } from 'react-bootstrap'

const lectionIcon = (lection, currentLection, trackers, finishCourseMessageIsActive) => {
  if (currentLection.id === lection.id && !finishCourseMessageIsActive) {
    return (<i className="far fa-pause-circle"></i>)
  } else if ( trackers.filter(tracker => tracker.completed_at !== null).findIndex(tracker => tracker.lection_id == lection.id) !== -1 ) {
    return (<i className="far fa-check-circle"></i>)
  } else if (lection.content.is_video) {
    return (<i className="fas fa-play-circle"></i>)
  } else if (lection.content.is_survey) {
    return (<i className="far fa-check-square"></i>)
  } else if (lection.content.file_type == 'application/pdf') {
    return (<i className="fas fa-file-pdf"></i>)
  } else if (lection.content.file_type && lection.content.file_type.includes('image/')) {
    return (<i className="fas fa-image"></i>)  
  } else if (lection.content.is_recording) {
    return (<i className="fas fa-video"></i>)
  } else {
    return (<i className="fas fa-file"></i>)
  }
}

const lectionNumber = (lections, currentLection) => {
  const lectionIndex = lections.findIndex(lection => lection.id == currentLection.id)
  return lectionIndex === -1 ? '' : lectionIndex + 1
}

const CourseSection = (props) => {
  const [open, setOpen] = useState(props.isOpened)
  const { section, course, locale, currentLection, loadLection, index, 
          trackers, showFinishCourseLink, showFinishCourseMessage,
          finishCourseMessageIsActive } = props
  
  useEffect( () => {
      if (props.isOpened) {
        setOpen(true)
      }
    }, [props.isOpened]
  )
  
  return (
    <Card className="course-section">
      <Card.Header className="d-flex justify-content-between" style={{cursor: 'pointer'}} onClick={() => setOpen(!open)}>
        <div className="section-title">{ window.t('course_viewer.section') } { index + 1 }: { section.title }</div>
        { open
          ?
          <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="css-i6dzq1"><polyline points="18 15 12 9 6 15"></polyline></svg>
          : 
          <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="css-i6dzq1"><polyline points="6 9 12 15 18 9"></polyline></svg>
        }
      </Card.Header>
      { open && 
        <Card.Body>
          <ul>
            { section.lections.map(lection => (
              <li key={'lection'+lection.id} className={currentLection.id == lection.id ? 'active' : ''}>
                <a href="#" onClick={(event) => loadLection(event, lection)} className="d-flex align-items-center lection-link">
                  { lectionIcon(lection, currentLection, trackers, finishCourseMessageIsActive) }
                  <span className="mg-l-5">{ lectionNumber(course.lections, lection) }. { lection.title }</span>
                  <span className="ml-auto tx-10 text-secondary">{ lection.content.duration }</span>
                </a>
                <div className="tx-12">
                  { (lection.resources.length > 0 || lection.links.length > 0) && 
                    <Dropdown>
                      <Dropdown.Toggle size="xs" variant="white" id="dropdown-basic">
                        <i className="far fa-folder-open"></i> { window.t('course_viewer.resources') }
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        { lection.resources.map(resource => (
                          <Dropdown.Item key={'resource'+resource.id} href={resource.url} target="_blank">
                            <i className="fas fa-download"></i> { resource.name }
                          </Dropdown.Item>  
                        ))}
                        { lection.links.map(link => (
                          <Dropdown.Item key={'link'+link.id} href={link.url} target="_blank">
                            <i className="fas fa-external-link-alt"></i> { link.name }
                          </Dropdown.Item>  
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  }
                </div>
              </li>
            ))}
            { showFinishCourseLink && 
              <li className={finishCourseMessageIsActive ? 'active' : ''}>
                <a href="#" onClick={showFinishCourseMessage} className="d-flex align-items-center lection-link">
                  <i className="fas fa-award"></i>
                  <span className="mg-l-5">{ course.lections.length + 1 }. { window.t('course_viewer.finish_course') }</span>
                </a>
              </li>  
            }
          </ul>
        </Card.Body>
      }
    </Card>
  )
}
  
export default CourseSection