import React, { useState, useEffect } from 'react'
import { client } from '../../Client'
import { Modal, Button, Dropdown } from 'react-bootstrap'
import Loader from '../../../helpers/Loader'
import { toastrNotification } from '../../../helpers/Toastr'      

export default function RecordingSelector({ lection, courseId, sectionId, onRecordingSelect }) {
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [recordings, setRecordings] = useState([])
  const [submitting, setSubmitting] = useState(false)
  const [selectedRecording, setSelectedRecording] = useState(null)

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = () => {
    setOpen(true)
    setLoading(true)
    client.getRecordings()
    .then(recordings => {
      setRecordings(recordings)
      setLoading(false)
    })
  }

  const handleRecordingSelect = (ev) => {
    setSelectedRecording(ev.target.value)
  }

  const submit = () => {
    if (!selectedRecording) {
      toastrNotification({ type: 'warning', title: 'Select a recording please'})
      return
    }

    setSubmitting(true)

    client.addCourseLectionContent(courseId, sectionId, lection.id, selectedRecording, 'recording')
    .then(lection => {
      setSubmitting(false)
      if (lection.id) {
        setSubmitting(false)
        handleClose()
        onRecordingSelect(sectionId, lection)
      } else {
        toastrNotification({ type: 'error', title: 'Unable to attach a recording' })
      }
    })
  }

  return (
      <>
        <Dropdown.Item as="button" onClick={handleOpen}>{ window.t('course.recording') }</Dropdown.Item>
        <Modal show={open} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{ window.t('course.select_recording') }</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            { loading &&
              <Loader />
            }
            { !loading && recordings.length > 0 &&
              <div>
                { recordings.map(recording => (
                  <div key={recording.id} className="custom-control custom-radio mg-b-5">
                    <input
                      type="radio"
                      id={'recording' + recording.id}
                      name="recording"
                      className="custom-control-input"
                      value={recording.id}
                      onChange={handleRecordingSelect}
                    />
                    <label className="custom-control-label" htmlFor={'recording' + recording.id}>{ recording.name }</label>
                  </div>
                ))}
              </div>
            }
            { !loading && recordings.length === 0 &&
              <div className="row">
                <div className="col-12 text-center">
                  { window.t('course.no_recordings') }
                </div>
              </div>
            }
          </Modal.Body>
          <Modal.Footer>
            <Button variant="link" onClick={handleClose}>{ window.t('cancel') }</Button>
            <Button className="btn btn-primary ml-auto" onClick={submit} disabled={submitting || !selectedRecording} >
              { submitting &&
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              }
              &#160;{ window.t('add') }
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    )
}