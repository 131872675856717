import React, { useState } from 'react'
import DocViewer, { DocViewerRenderers } from 'react-doc-viewer'
import { Modal, ModalBody, ModalFooter, 
         ModalHeader, ModalTitle, Button } from 'react-bootstrap'

export default function LectionDocumentPreview({ lection }) {
  const [show, setShow] = useState(false)

  return (
    <>
      <Button 
        variant="link" 
        style={{padding: '0px', marginTop: '-15px'}} 
        onClick={() => setShow(true)}
      >
        { lection.content.name }
      </Button>
      <Modal
        size="lg"
        show={show}
        onHide={() => setShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            { lection.content.name }
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DocViewer 
            pluginRenderers={DocViewerRenderers} 
            documents={[{uri: lection.content.url}]}
            style={{minHeight: '400px'}}
            config={{
              header: {
                disableHeader: true,
                disableFileName: true,
                retainURLParams: false
              }
            }}
          />
        </Modal.Body>
      </Modal>
    </>
  )
}