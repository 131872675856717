import React from 'react'
import { client } from './../Client'
import Uppy from '@uppy/core'
import Russian from '@uppy/locales/lib/ru_RU'
import Tus from '@uppy/tus'
import { DashboardModal } from '@uppy/react'
import { toastrNotification } from '../../helpers/Toastr'

import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'

class SubmissionFilesUploader extends React.Component {
  
  constructor (props) {
    super(props)

    this.state = {
      modalOpen: false,
      uploadedFiles: 0,
      isReady: true,
      submissionId: null,
      isLoading: false
    }
    
    const locale = window.locale == "ru" ? Russian : 'en_EN'

    this.uppy = new Uppy({
      restrictions: { maxNumberOfFiles: 10 },
      autoProceed: true,
      locale: locale
    }).use(Tus, { endpoint: window.upload_endpoint, chunkSize: 5*1024*1024 })
    
    this.uppy.on('upload-success', (file, response) => {
      let fileData = {title: file.name,
                      file_orig_name: file.name,
                      file_url: response.uploadURL,
                      file_size: file.size,
                      file_type: file.meta.type}
      this.createSubmissionAttachment(fileData)
    })
        
    this.uppy.on('file-removed', (file, reason) => {
      if (file.uploadURL) {
        client.deleteFile(file.uploadURL)  
      }
    })
    
    this.handleOpen = this.handleOpen.bind(this)
    this.handleClose = this.handleClose.bind(this)
  }
  
  componentDidMount() {
    this.setState({ submissionId: window.submission_id })
  }
  
  componentWillUnmount() {
    const { uploadedFiles, submissionId } = this.state
    
    if ( uploadedFiles == 0 && submissionId ) {
      client.deleteHomeworkSubmission(window.homework_id, submissionId)
    }
    
    this.uppy.close()
  }
  
  handleOpen () {
    this.initSubmission()
  }

  handleClose () {
    const { uploadedFiles, submissionId } = this.state
    
    //if ( uploadedFiles == 0 && submissionId ) {
    //  client.deleteHomeworkSubmission(window.homework_id, submissionId)
    //}
    
    this.setState({
      modalOpen: false
    }, () => {
      if (this.state.uploadedFiles > 0) { 
        Turbo.visit(Routes.pupil_homework_submissions_path(window.homework_id, {locale: window.locale}))
      }
    })
  }
  
  initSubmission() {
    if (this.state.submissionId) {
      this.setState({ modalOpen: true })
      return
    }
    this.setState({ isLoading: true })
    client.createHomeworkSubmission(window.homework_id) 
    .then((response) => {
      if ( response.submission_id ) {
        this.setState({ submissionId: response.submission_id, 
                        modalOpen: true, 
                        isLoading: false 
                      })  
      } else {
        //toastr.error(window.t('file.storage_limit_is_exceeded'))
        toastr.error("Unable to upload")
      }
    })
  }
  
  createSubmissionAttachment(fileData) {
    client.createHomeworkSubmissionAttachment(fileData, this.state.submissionId)
    .then((response) => {
      if (response.file_id) {
        this.setState({ uploadedFiles: this.state.uploadedFiles + 1 })
      }
    })
  }

  render () {
    const { isLoading, modalOpen } = this.state
    
    return (
      <div>
        { !window.submission_id && 
          <a className="btn btn-sm btn-white" style={{cursor: 'pointer'}} onClick={this.handleOpen} disabled={isLoading}>
            { isLoading &&
              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            }
            { !isLoading &&
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-plus"><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>
            }
            <span className="mg-l-5">{ window.t("submission.new") }</span>
          </a>
        }
        { window.submission_id &&
          <a className="btn btn-xs btn-white" style={{cursor: 'pointer'}} onClick={this.handleOpen}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-plus"><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>
            { window.t("submission.add_files") }
          </a>
        }  
        <DashboardModal
          uppy={this.uppy}
          closeModalOnClickOutside
          open={modalOpen}
          onRequestClose={this.handleClose}
          target={document.body}
          proudlyDisplayPoweredByUppy={false}
          showLinkToFileUploadResult={false}
          showRemoveButtonAfterComplete={true}
          doneButtonHandler={this.handleClose}
        />
      </div>
    );
  }
}

export default SubmissionFilesUploader