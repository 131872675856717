import React from 'react'

export default function SurveyReportRowExpanded({ data, questions }) {
  
  return (
    <div className="row">
      <div className="col-12 pd-15">
        { questions.map(question => (
            <div key={question.selector} className="row">
              <div className="col-12 mg-b-10">
                <div><strong>{ question.name }:</strong></div>
                <div>{ data[question.selector] }</div>
              </div>
            </div>
          ))
        }
      </div>
    </div>
  )
}
