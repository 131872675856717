import React, { Component } from 'react'
import ActionCable  from 'actioncable'
import { client } from './../Client'
import { Waypoint } from 'react-waypoint'
import { Element, Events, animateScroll as scroll, scroller } from 'react-scroll'
import debounce from 'lodash/debounce'

import Dropdown from 'react-bootstrap/Dropdown'
import DropdownToggle from 'react-bootstrap/DropdownToggle'
import DropdownItem from 'react-bootstrap/DropdownItem'
import DropdownMenu from 'react-bootstrap/DropdownMenu'

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href=""
    ref={ref} 
    style={{color: "#001737"}}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </a>
))

class ConversationMessages extends Component {  
  
  state = {
    messages: [],
    page: 1,
    isLoading: false,
    messagesEnded: false
  }
  
  constructor(props) {
    super(props)
    this.loadMoreMessages = debounce(this.loadMoreMessages, 200)
    this.prevYpostion = 0
    this.userScrolledUp = false
    this.userScrolledDown = false
  }
  
  componentDidMount() {
    //this.loadMoreMessages()
  }
  
  handleNavigation(e) {
    const chatContainer = e.currentTarget
    
    if (this.prevYpostion > chatContainer.scrollTop) {
      this.userScrolledUp = true
      this.userScrolledDown = false
    } else {
      this.userScrolledDown = true
      this.userScrolledUp = false
    }
    this.prevYpostion = chatContainer.scrollTop
  }
    
  componentDidUpdate(prevProps, prevState) {
    if (prevState.messages !== this.state.messages ) { //&& this.state.page <= 2
      this.scrollToBottom()
    }
    if (prevProps.search !== this.props.search) {
      this.setState({ 
        page: 1, 
        messages: [], 
        messagesEnded: false 
      }, () => {
        this.loadMoreMessages()
      })
    }
  }
    
  scrollToBottom = () => {
    this.convoBottomSide.scrollIntoView({ behavior: "smooth" });
  }
    
  addMessage(newMessage) {
    this.setState({ messages: this.state.messages.concat([newMessage]) })
    if ( newMessage.user.id === this.props.current_user_id ) {
      this.scrollToBottom()
    }
  }
  
  deleteMessageFromList(messageId) {
    const newMessages = this.state.messages.filter(m => m.id !== messageId);
    this.setState({ messages: newMessages });
  }
  
  deleteMessage(ev, message) {
    if ( ev ) { ev.preventDefault() }
    this.props.subscription.perform('delete_conversation_message', { message_id: message.id });
  }
  
  loadMoreMessages() {
    if (this.state.isLoading) return
    this.setState({ isLoading: true })
    const topMessage = this.state.messages[0]
    client.getConversationMessages(this.state.page, this.props.conversation.id, this.props.search)
    .then((messages) => {
       const messagesEnded = messages.length > 0 ? false : true
       let page = this.state.page
       this.setState({  messages: messages.concat(this.state.messages), 
                        page: page + 1, 
                        isLoading: false, 
                        messagesEnded: messagesEnded
                     })
       if ( topMessage && page !== 1 ) {
         scroller.scrollTo('message'+topMessage.id, {containerId: 'chat'})
       }
    })
  }

  renderWaypoint = () => {
    const { messagesEnded } = this.state
    
    if ( !messagesEnded ) {
      return (
        <Waypoint
          onEnter={this.loadMoreMessages.bind(this)}
        />
      )
    }
  }

  renderLoader = () => {
    if (this.state.isLoading && !this.state.messagesEnded) {
      return (
        <div className="row">
          <div className="col-md-12 text-center pt-3">
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      )
    }
  }

  render() {
    
    const { messages } = this.state
    const { can_update_conversation, conversation, current_user_id } = this.props
    
    return (
      <div className="chat-content-body" id="chat">
        <div className="chat-group">
          { this.renderLoader() }
          { this.renderWaypoint() }
          { messages.map(message => (
            <Element name={'message'+message.id} key={'convMessage'+message.id} className="element">
              <div className="media" key={"message" + message.id}>
                { !message.user.avatar_present && 
                  <div className="avatar avatar-sm">
                    <span className="avatar-initial rounded-circle">{ message.user.first_name.charAt(0).toUpperCase() }</span>
                  </div>
                }
                { message.user.avatar_present &&
                  <div className="avatar avatar-sm">
                    <img src={ message.user.avatar_url } className="rounded-circle" />
                  </div>
                }
                <div className="media-body">
                  <h6>
                    { conversation.admin_id == current_user_id && 
                      conversation.admin_is_tutor &&
                      message.user.id !== current_user_id 
                      ?
                      <a href={ Routes.details_tutor_contact_path(message.user.uid) } data-modal="true" className="text-body">
                        { message.user.school_member ? message.user.full_name : message.user.first_name }
                      </a>
                      :
                      <span>{ message.user.school_member ? message.user.full_name : message.user.first_name }</span>
                    } <small>{ message.created_date }</small>
                    { message.user.id == current_user_id &&
                      <Dropdown className="d-inline ml-1">
                        <Dropdown.Toggle as={CustomToggle}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item eventKey="1" onClick={(event) => this.deleteMessage(event, message)}>
                            {window.t('delete')}
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    }  
                  </h6>
                  <div dangerouslySetInnerHTML={{__html: message.body}} />
                  { message.attachments.map(attachment => (
                    <div className={"mb-3 " + (attachment.is_video ? "video-attachment" : "document-attachment") } key={ "attachment" + attachment.id } >
                      { attachment.deleted &&
                        <div dangerouslySetInnerHTML={{__html: `<small>*${window.t('chat.conversation.attachment_deleted')}</small>`}} />
                      }
                      { attachment.is_video && !attachment.deleted &&
                        <div className="card">
                          <a href={ Routes.pupil_video_path(attachment.content_id) } data-modal="true" >
                            <img src={ attachment.image_url } className="card-img-top" alt={ attachment.name} />
                          </a>
                          <div className="card-body">
                            <a href={ Routes.pupil_video_path(attachment.content_id) } className="card-link" data-modal="true" >
                              { attachment.name }
                            </a>
                          </div>
                        </div>
                      }
                      { !attachment.is_video && !attachment.deleted &&
                        <div className="row no-gutters">
                          <div className="col-12">
                            <a href={ attachment.url } target="_blank">
                              <i className={attachment.icon + " " + attachment.icon_color}></i> { attachment.name }
                            </a>
                          </div>
                        </div>
                      }
                    </div>
                  ))}
                </div>
              </div>                
            </Element> 
          ))}
        </div>
        <div ref={(el) => { this.convoBottomSide = el; }}></div>
      </div>
    )
  }
  
}

export default ConversationMessages
