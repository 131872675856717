import React from 'react'
import { Card, Button } from 'react-bootstrap'
import Swal from 'sweetalert2'
import { client } from '../../Client'
import CourseSectionForm from './CourseSectionForm'
import CourseLectionForm from './CourseLectionForm'
import CourseLection from './CourseLection'
import { sortableHandle, SortableContainer, SortableElement } from 'react-sortable-hoc'

const DragHandle = sortableHandle(() => (
  <span className="mg-r-10">
    <i className="fas fa-arrows-alt-v"></i>
  </span>
))

const SortableLection = SortableElement(({lection,
                                          courseId,
                                          sectionId,
                                          onUpdateLection,
                                          onDeleteLection,
                                          locale
                                        }) => {
  return (
    <CourseLection
      lection={lection}
      courseId={courseId}
      sectionId={sectionId}
      onUpdateLection={onUpdateLection}
      onDeleteLection={onDeleteLection}
      locale={locale}
    />
  )
})

const SortableLectionsList = SortableContainer(({lections,
                                                 lection,
                                                 courseId,
                                                 sectionId,
                                                 onUpdateLection,
                                                 onDeleteLection,
                                                 locale
                                               }) => {
  return (
    <div>
      {lections.map((lection, index) => (
        <SortableLection
          lection={lection}
          key={`lection-${lection.id}`}
          index={index}
          courseId={courseId}
          sectionId={sectionId}
          onUpdateLection={onUpdateLection}
          onDeleteLection={onDeleteLection}
          locale={locale}
        />
      ))}
    </div>
  )
})

class CourseSection extends React.Component {

  state = {
    open: false
  }

  close = () => {
    this.setState({ open: false })
  }

  open = () => {
    this.setState({ open: true })
  }

  handleChevronClick = (event) => {
    event.preventDefault()
    this.setState({ open: !this.state.open })
  }

  showDeleteSectionConfirmation = (event, section) => {
    event.preventDefault()
    Swal.fire({
      title: window.t('course.delete_section_confirm'),
      text: section.title,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: window.t('_yes'),
      cancelButtonText: window.t('_no')
    }).then((result) => {
      if (result.value) {
        const courseId = this.props.courseId
        client.deleteCourseSection(courseId, section.id).
        then((result) => {
          this.props.onDeleteSection(section)
        })
      }
    })
  }

  render() {
    const { section, courseId, locale } = this.props
    const { open } = this.state

    return (
      <Card bg="light" border="secondary" style={{marginBottom: '15px'}}>
        <Card.Header className="d-flex">
          <div>
            <DragHandle />
            <h6 className="d-inline mg-r-10">
              { section.title }
            </h6>
            <CourseSectionForm
              courseId={courseId}
              onAddSection={this.addSection}
              section={section}
              onUpdateSection={this.props.onUpdateSection}
              mode="edit"
            />
            <button className="btn btn-xs mg-r-5 btn-white" onClick={event => this.showDeleteSectionConfirmation(event, section)} style={{padding: '3px 7px'}}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-trash"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path></svg>
            </button>
          </div>

          <div className="ml-auto">
            { (section.lections.length == 0 || open) &&
              <span className="d-none d-md-inline-block">
                <CourseLectionForm
                  sectionId={section.id}
                  courseId={courseId}
                  onAddLection={this.props.onAddLection}
                  mode="new"
                />
              </span>
            }
            <a href="#" className="ml-3 text-body" onClick={this.handleChevronClick}>
              { open
                ?
                <span><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-up"><polyline points="18 15 12 9 6 15"></polyline></svg></span>
                :
                <span><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg></span>
              }
            </a>
          </div>
        </Card.Header>
        { open &&
          <Card.Body>
            <div className="d-block d-md-none mg-b-15 text-right">
              <CourseLectionForm
                sectionId={section.id}
                courseId={courseId}
                onAddLection={this.props.onAddLection}
                mode="new"
              />
            </div>

            <SortableLectionsList
              lections={section.lections}
              courseId={courseId}
              sectionId={section.id}
              onUpdateLection={this.props.onUpdateLection}
              onDeleteLection={this.props.onDeleteLection}
              onSortEnd={({oldIndex, newIndex}) => this.props.onLectionsSortEnd(section, oldIndex, newIndex)}
              useDragHandle
              locale={locale}
            />
          </Card.Body>
        }
      </Card>
    )
  }

}

export default CourseSection