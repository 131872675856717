import React from 'react'
import ReactDOM from 'react-dom'
import CourseViewer from './CourseViewer'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

document.addEventListener('turbo:load', () => {
   const viewerContainer = document.getElementById('courseViewer')

   if (viewerContainer) {
     const course = JSON.parse(viewerContainer.dataset.course),
           trackers = JSON.parse(viewerContainer.dataset.trackers),
           returnUrl = viewerContainer.dataset.returnUrl,
           license = JSON.parse(viewerContainer.dataset.license)

     ReactDOM.render(
       <Router>
         <Switch>
            <Route
              path="/:locale/tutor/courses/:courseSlug/lecture/:lectionId"
              render={props => <CourseViewer {...props} course={course} trackers={trackers} preview={true} returnUrl={returnUrl} />}
            />
            <Route
              path="/:locale/pupil/courses/:courseSlug/lecture/:lectionId"
              render={(props) => <CourseViewer preview={false} {...props} course={course} trackers={trackers} returnUrl={returnUrl} license={license} />}
            />
         </Switch>
       </Router>,
       viewerContainer,
     );

     //document.addEventListener('turbo:before-visit', () => {
     //  ReactDOM.unmountComponentAtNode(viewerContainer)
     //})
   }
})