import React from 'react'
import ReactDOM from 'react-dom'
import GroupFilesTree from '../GroupFilesTree'

document.addEventListener('turbo:load', () => {
  const homeworkFilesTreeBtnContainer = document.getElementById('homeworkFilesTreeBtn')
  
  if (homeworkFilesTreeBtnContainer) {
    ReactDOM.render(
      <GroupFilesTree target="homework" />,
      homeworkFilesTreeBtnContainer
    );
    
    //document.addEventListener('turbo:before-visit', () => {
    //  ReactDOM.unmountComponentAtNode(homeworkFilesTreeBtnContainer)
    //})
  }
  
})