import axios from 'axios'

class Client {

  getNotifications() {
    return this.callApi('/api/v1/notifications.json')
      .catch((error) => {
        this.handleApiError(error);
        return [];
      });
  }

  getConversationMessages(page, conversationId, search) {
    return this.callApi("/api/v1/conversation_messages.json?conversation_id=" + conversationId + "&page=" + page + "&search=" + search)
      .catch((error) => {
        this.handleApiError(error);
        return [];
      });
  }

  getUnreadMessages(page) {
    return this.callApi("/api/v1/conversation_messages/get_unread_messages.json?page=" + page)
      .catch((error) => {
        this.handleApiError(error);
        return [];
      });
  }

  getTutorFileStorageLimit() {
    return this.callApi("/api/v1/settings/get_storage_limit.json")
      .catch((error) => {
        this.handleApiError(error);
        return [];
      });
  }

  getFile(fileId) {
    return this.callApi('/api/v1/files/' + fileId + '.json')
      .catch((error) => {
        client.handleApiError(error)
        return {}
      })
  }

  createFile(fileData) {
    return this.callApi('/api/v1/files.json', {}, 'POST', {file: fileData})
      .catch((error) => {
        client.handleApiError(error);
        return {};
      });
  }

  createConversationFile(fileData, conversationId) {
    return this.callApi('/api/v1/files/create_conversation_file.json', {}, 'POST', {file: fileData, conversation_id: conversationId})
      .catch((error) => {
        client.handleApiError(error);
        return {};
      });
  }

  deleteFile(fileUrl) {
    return this.callApi('/api/v1/files/destroy_file.json', {}, 'POST', {file_url: fileUrl})
      .catch((error) => {
        client.handleApiError(error);
        return {};
      });
  }

  getFileStructure(onlyVideo=false) {
    return this.callApi("/api/v1/files/get_file_structure.json?only_video=" + onlyVideo)
      .catch((error) => {
        this.handleApiError(error);
        return [];
      });
  }

  createGroupFiles(filesFolders, groupId) {
    return this.callApi('/api/v1/group_files.json', {}, 'POST', {files_folders: filesFolders, group_id: groupId})
      .catch((error) => {
        client.handleApiError(error);
        return {};
      });
  }

  createHomeworkFiles(fileIds, homeworkId) {
    return this.callApi('/api/v1/homework_files.json', {}, 'POST', {file_ids: fileIds, homework_id: homeworkId})
      .catch((error) => {
        client.handleApiError(error);
        return {};
      });
  }

  createHomeworkSubmission(homeworkId) {
    return this.callApi('/api/v1/homework_submissions.json', {}, 'POST', {homework_id: homeworkId})
      .catch((error) => {
        client.handleApiError(error);
        return {};
      });
  }

  deleteHomeworkSubmission(homeworkId, submissionId) {
    return this.callApi('/api/v1/homework_submissions/' + submissionId + '.json', {}, 'DELETE', {homework_id: homeworkId})
      .catch((error) => {
        client.handleApiError(error);
        return {};
      });
  }

  createHomeworkSubmissionAttachment(fileData, submissionId) {
    return this.callApi('/api/v1/homework_submissions/' + submissionId + '/add_attachment.json', {}, 'POST', {file: fileData})
      .catch((error) => {
        client.handleApiError(error)
        return {}
      });
  }

  getCourses() {
    return this.callApi("/api/v1/courses/")
      .catch((error) => {
        this.handleApiError(error)
        return {}
      })
  }

  getCourse(courseId) {
    return this.callApi("/api/v1/courses/" + courseId)
      .catch((error) => {
        this.handleApiError(error)
        return {}
      })
  }

  updateCourse(courseId, data) {
    return this.callApi('/api/v1/courses/' + courseId + '.json', {}, 'PUT', {course: data})
      .catch((error) => {
        this.handleApiError(error)
        return {}
      });
  }

  deleteCourse(courseId) {
    return this.callApi('/api/v1/courses/' + courseId + '.json', {}, 'DELETE')
      .catch((error) => {
        client.handleApiError(error);
        return {};
      });
  }

  getCoursePackageItems(courseId) {
    return this.callApi('/api/v1/courses/' + courseId + '/package_items.json')
      .catch((error) => {
        this.handleApiError(error)
        return {}
      })
  }

  createCoursePackageItems(courseId, data, updateLicenses) {
    return this.callApi('/api/v1/courses/' + courseId + '/package_items.json', {}, 'POST', {package_items: data, update_licenses: updateLicenses})
      .catch((error) => {
        this.handleApiError(error)
        return {}
      })
  }

  deleteCoursePackageItems(courseId, data, updateLicenses) {
    return this.callApi('/api/v1/courses/' + courseId + '/package_items/delete_items.json', {}, 'POST', {package_items: data, update_licenses: updateLicenses})
      .catch((error) => {
        this.handleApiError(error)
        return {}
      })
  }

  sortPackageItems(courseId, packageItemIds) {
    return client.callApi('/api/v1/courses/' + courseId + '/package_items/sort.json', {}, 'PUT', { package_items: packageItemIds })
      .catch((error) => {
        client.handleApiError(error);
        return {};
      });
  }

  getCourseSections(courseId) {
    return this.callApi('/api/v1/courses/' + courseId + '/sections.json')
      .catch((error) => {
        this.handleApiError(error)
        return {}
      });
  }

  createCourseSection(courseId, data) {
    return this.callApi('/api/v1/courses/' + courseId + '/sections.json', {}, 'POST', {section: data})
      .catch((error) => {
        this.handleApiError(error)
        return {}
      });
  }

  updateCourseSection(courseId, sectionId, data) {
    return this.callApi('/api/v1/courses/' + courseId + '/sections/' + sectionId + '.json', {}, 'PUT', {section: data})
      .catch((error) => {
        this.handleApiError(error)
        return {}
      });
  }

  deleteCourseSection(courseId, sectionId) {
    return this.callApi('/api/v1/courses/' + courseId + '/sections/' + sectionId + '.json', {}, 'DELETE')
      .catch((error) => {
        this.handleApiError(error)
        return {}
      });
  }

  sortSections(courseId, sectionIds) {
    return client.callApi('/api/v1/courses/' + courseId + '/sections/sort.json', {}, 'PUT', { sections: sectionIds })
      .catch((error) => {
        client.handleApiError(error);
        return {};
      });
  }

  createCourseLection(courseId, sectionId, data) {
    return this.callApi('/api/v1/courses/' + courseId + '/sections/' + sectionId + '/lections.json', {}, 'POST', {lection: data})
      .catch((error) => {
        this.handleApiError(error)
        return {}
      });
  }

  updateCourseLection(courseId, sectionId, lectionId, data) {
    return this.callApi('/api/v1/courses/' + courseId + '/sections/' + sectionId + '/lections/' + lectionId + '.json', {}, 'PUT', {lection: data})
      .catch((error) => {
        this.handleApiError(error)
        return {}
      });
  }

  deleteCourseLection(courseId, sectionId, lectionId) {
    return this.callApi('/api/v1/courses/' + courseId + '/sections/' + sectionId + '/lections/' + lectionId + '.json', {}, 'DELETE')
      .catch((error) => {
        this.handleApiError(error)
        return {}
      });
  }

  addCourseLectionContent(courseId, sectionId, lectionId, contentId, contentType) {
    return this.callApi('/api/v1/courses/' + courseId + '/sections/' + sectionId + '/lections/' + lectionId + '/add_content.json', {}, 'PUT', { content_id: contentId, content_type: contentType })
      .catch((error) => {
        this.handleApiError(error)
        return {}
      });
  }

  addCourseLectionResources(courseId, sectionId, lectionId, fileIds) {
    return this.callApi('/api/v1/courses/' + courseId + '/sections/' + sectionId + '/lections/' + lectionId + '/add_resources.json', {}, 'PUT', {file_ids: fileIds})
      .catch((error) => {
        this.handleApiError(error)
        return {}
      });
  }

  deleteCourseLectionResource(courseId, sectionId, lectionId, resourceId) {
    return this.callApi('/api/v1/courses/' + courseId + '/sections/' + sectionId + '/lections/' + lectionId + '/remove_resource.json?resource_id=' + resourceId, {}, 'DELETE')
      .catch((error) => {
        this.handleApiError(error)
        return {}
      });
  }

  addCourseLectionLink(courseId, sectionId, lectionId, linkData) {
    return this.callApi('/api/v1/courses/' + courseId + '/sections/' + sectionId + '/lections/' + lectionId + '/add_link.json', {}, 'PUT', {link: linkData})
      .catch((error) => {
        this.handleApiError(error)
        return {}
      });
  }

  deleteCourseLectionLink(courseId, sectionId, lectionId, linkId) {
    return this.callApi('/api/v1/courses/' + courseId + '/sections/' + sectionId + '/lections/' + lectionId + '/remove_link.json?link_id=' + linkId, {}, 'DELETE')
      .catch((error) => {
        this.handleApiError(error)
        return {}
      });
  }

  markLectionStarted(courseId, lectionId, licenseId) {
    return this.callApi('/api/v1/courses/' + courseId + '/lection_trackers.json', {}, 'POST', {lection_id: lectionId, license_id: licenseId})
      .catch((error) => {
        this.handleApiError(error)
        return {}
      });
  }

  markLectionCompleted(courseId, lectionId, licenseId) {
    return this.callApi('/api/v1/courses/' + courseId + '/lection_trackers/complete_lection.json', {}, 'PUT', {lection_id: lectionId, license_id: licenseId})
      .catch((error) => {
        this.handleApiError(error)
        return {}
      });
  }

  sortLections(courseId, sectionId, lectionIds) {
    return client.callApi('/api/v1/courses/' + courseId + '/sections/' + sectionId + '/lections/sort.json', {}, 'PUT', { lections: lectionIds })
      .catch((error) => {
        client.handleApiError(error);
        return {};
      });
  }

  getCourseLicenses(courseId) {
    return this.callApi('/api/v1/courses/' + courseId + '/licenses.json')
      .catch((error) => {
        this.handleApiError(error)
        return {}
      });
  }

  getTransformationBookStatus(bookId) {
    return this.callApi('/api/v1/transformation_course_books/' + bookId + '/get_status.json')
      .catch((error) => {
        this.handleApiError(error)
        return {}
      });
  }

  getSurveys() {
    return this.callApi('/api/v1/surveys.json')
      .catch((error) => {
        this.handleApiError(error)
        return {}
      })
  }

  getSurvey(surveyId) {
    return this.callApi('/api/v1/surveys/' + surveyId + '.json')
      .catch((error) => {
        this.handleApiError(error)
        return {}
      })
  }

  updateSurvey(surveyId, data) {
    return this.callApi('/api/v1/surveys/' + surveyId + '.json', {}, 'PUT', {survey: data})
      .catch((error) => {
        this.handleApiError(error)
        return {}
      })
  }

  deleteSurvey(surveyId) {
    return this.callApi('/api/v1/surveys/' + surveyId + '.json', {}, 'DELETE')
      .catch((error) => {
        client.handleApiError(error);
        return {};
      });
  }

  createSurveyQuestion(surveyId, data) {
    return this.callApi('/api/v1/surveys/' + surveyId + '/questions.json', {}, 'POST', { question: data })
      .catch((error) => {
        this.handleApiError(error)
        return {}
      });
  }

  updateSurveyQuestion(surveyId, questionId, data) {
    return this.callApi('/api/v1/surveys/' + surveyId + '/questions/' + questionId + '.json', {}, 'PUT', { question: data })
      .catch((error) => {
        this.handleApiError(error)
        return {}
      });
  }

  deleteSurveyQuestion(surveyId, questionId) {
    return this.callApi('/api/v1/surveys/' + surveyId + '/questions/' + questionId + '.json', {}, 'DELETE')
      .catch((error) => {
        this.handleApiError(error)
        return {}
      });
  }

  sortSurveyQuestions(surveyId, questionIds) {
    return client.callApi('/api/v1/surveys/' + surveyId + '/questions/sort.json', {}, 'PUT', { questions: questionIds })
      .catch((error) => {
        client.handleApiError(error);
        return {};
      });
  }

  getSurveyAnswers(surveyId) {
    return this.callApi('/api/v1/surveys/' + surveyId + '/answers.json')
      .catch((error) => {
        this.handleApiError(error)
        return {}
      });
  }

  getSurveyUserAnswers(surveyId, licenseId) {
    return this.callApi('/api/v1/surveys/' + surveyId + '/answers/user_answers.json?license_id=' + licenseId)
      .catch((error) => {
        this.handleApiError(error)
        return {}
      });
  }

  createSurveyAnswers(surveyId, licenseId, data) {
    return this.callApi('/api/v1/surveys/' + surveyId + '/answers.json', {}, 'POST', { answers: data, license_id: licenseId })
      .catch((error) => {
        this.handleApiError(error)
        return {}
      });
  }

  getRecordings() {
    return this.callApi('/api/v1/recordings.json')
      .catch((error) => {
        this.handleApiError(error)
        return {}
      });
  }

  createInvitation(invitationData) {
    return this.callApi('/api/v1/invitations.json', {}, 'POST', {invitation: invitationData})
      .catch((error) => {
        client.handleApiError(error);
        return {};
      });
  }

  callApi(url, headers = {}, method = 'get', body = null) {
    return axios({
      method: method,
      url: url,
      headers: Object.assign({ accept: 'application/json',
                               'Content-Type': 'application/json',
                               'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
                              }, headers),
      data: body,
      withCredentials: true
    })
    .then(this.checkStatus)
    .then(this.getResponseData)
    .then(this.checkSessionExpiration)
  }

  checkStatus(response) {
    if ((response.status >= 200 && response.status < 300) || response.status === 422) {
      return response;
    } else {
      const error = new Error(`HTTP Error ${response.statusText}`);
      error.status = response.statusText;
      error.response = response;
      console.log(error.response);
      throw error;
    }
  }

  getResponseData(response) {
    return response.data
  }

  checkSessionExpiration(responseData) {
    if ( responseData.session_expired ) {
      swal({
        title: window.t("session.expired"),
        text: window.t("session.page_will_be_reloaded"),
        type: "error",
        showCancelButton: false,
        showConfirmButton: true
      });
      Turbo.visit(window.location);
    } else {
      return responseData;
    }
  }

  handleApiError(error) {
    console.log('handle error:' + error.message);
  }

}

export const client = new Client()
