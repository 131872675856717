import React from 'react'
import DocViewer, { DocViewerRenderers } from 'react-doc-viewer'

const ContentDocument = React.memo(props => {
  const lection = props.lection
  
  return (
    <div>
      <DocViewer 
        pluginRenderers={DocViewerRenderers} 
        documents={[{uri: lection.content.url}]}
        style={{minHeight: '400px'}}
        config={{
          header: {
            disableHeader: true,
            disableFileName: true,
            retainURLParams: false
          }
        }}
      />
    </div>
  )
})

export default ContentDocument