import React from 'react'
import ReactDOM from 'react-dom'
import GroupFilesTree from '../GroupFilesTree'

document.addEventListener('turbo:load', () => {
  const filesTreeBtnContainer = document.getElementById('filesTreeBtn')
  
  if (filesTreeBtnContainer) {
    ReactDOM.render(
      <GroupFilesTree target="group" />,
      document.getElementById('filesTreeBtn'),
    );
    
    //document.addEventListener('turbo:before-visit', () => {
    //  ReactDOM.unmountComponentAtNode(filesTreeBtnContainer)
    //})
  }
    
})