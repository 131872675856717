import React from 'react'
import { client } from '../Client'
import { Formik, Field } from 'formik'
import * as Yup from 'yup'
import CourseCreatorNav from './CourseCreatorNav'
import GroupFilesTree from '../GroupFilesTree'
import { toastrNotification } from '../../helpers/Toastr'
import { Editor } from '@tinymce/tinymce-react'
import ImageCropper from './ImageCropper'
import noimage from '../../images/noimage.png'
  
class Details extends React.Component {
  
  constructor(props) {
    super(props)
    
    this.state = {
      descriptionEditorContent: props.course.description,
      promoVideo: props.course.promo_video,
      imageSrc: null
    }
  }
  
  handleEditorChange = (content, editor)  => {
    this.setState({ descriptionEditorContent: content });
  }
  
  updateImageSrc = (src) => {
    this.setState({ imageSrc: src })
  }
  
  handlePromoVideoSelect = (selectedFile) => {
    this.setState({ promoVideo: selectedFile })
  }
  
  openPromoVideoSelector = () => {
    this.refs.promoVideoSelector.handleOpen()
  }
    
  render() {
    const { locale, courseId } = this.props.match.params,
          { course, categories } = this.props,
          { descriptionEditorContent, imageSrc, promoVideo }  = this.state
    
    const validationSchema = Yup.object().shape({
      title: Yup.string()
      .max(60, window.t('form.too_long'))
      .required(window.t('form.required')),
      sub_title: Yup.string()
      .max(120, window.t('form.too_long')),
      expiry_period: Yup.number()
      .min(0)
      .integer()
    })
    
    return (
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header">
                <CourseCreatorNav 
                  currentForm={'details'}
                  locale={locale}
                  course={course}
                />
              </div>
              <div className="card-body">
                <Formik
                  initialValues={{ title: course.title, 
                                   sub_title: course.sub_title || "",
                                   course_category_id: course.category.id,
                                   expiry_period: course.expiry_period || 0,
                                   description: descriptionEditorContent,
                                   promo_video_id: course.promo_video_id
                                }}
                  validationSchema={validationSchema}
                  onSubmit={(values, { setSubmitting }) => {
                    let data = values
                    data['description'] = this.state.descriptionEditorContent
                    if (imageSrc) { data['image'] = imageSrc }
                    if (promoVideo && promoVideo.id) { data['promo_video_id'] = promoVideo.id }
                    client.updateCourse(course.id, values)
                    .then((course) => {
                      if (course.id) {
                        toastrNotification({ type: 'success', title: window.t('updated') })
                        this.props.onUpdateCourse(course)
                      } else {
                        toastrNotification({ type: 'error', title:  window.t('not_updated') })
                      }
                      setSubmitting(false)
                    })
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isValid,
                    isSubmitting,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <label>{ window.t('course.title') }</label>
                          <div className="input-group">
                             <input
                               type="text"
                               name="title"
                               className={"form-control " + (errors.title && touched.title ? 'is-invalid' : '')}
                               onChange={handleChange}
                               onBlur={handleBlur}
                               value={values.title}
                               placeholder={ window.t('course.title_placeholder') }
                             />
                             <div className="input-group-append">
                               <span className="input-group-text" id="title-addon">{ values.title.length > 60 ? 0 : (60 - values.title.length) }</span>
                             </div>
                             {errors.title && touched.title && 
                               <div className="invalid-feedback">{errors.title}</div>
                             }
                          </div>
                        </div>
                        <div className="form-group col-md-6">
                          <label>{ window.t('course.category') }</label>
                          <select
                            onChange={handleChange} 
                            className="form-control"
                            value={values.course_category_id}
                            onBlur={handleBlur}
                            name="course_category_id"
                          >
                           { categories.map(category => 
                             <option 
                               key={category.id} 
                               value={category.id}
                             >
                               {category.name}
                             </option>
                           )}  
                          </select>
                        </div>
                      </div>
                      <div className="form-group">
                        <label>{ window.t('course.subtitle') }</label>
                        <div className="input-group">
                           <input
                             type="text"
                             name="sub_title"
                             className={"form-control " + (errors.sub_title && touched.sub_title ? 'is-invalid' : '')}
                             onChange={handleChange}
                             onBlur={handleBlur}
                             value={values.sub_title}
                             placeholder={ window.t('course.subtitle_placeholder') }
                           />
                           <div className="input-group-append">
                             <span className="input-group-text" id="subtitle-addon">{ values.sub_title.length > 120 ? 0 : (120 - values.sub_title.length) }</span>
                           </div>
                           {errors.sub_title && touched.sub_title && 
                             <div className="invalid-feedback">{errors.sub_title}</div>
                           }
                        </div>
                      </div>
                      <div className="form-group">
                        <label>{ window.t('course.expiry_period') }</label>
                        <Field as="select" name="expiry_period" className="form-control">
                          <option value="0">{ window.t('course.lifetime_access') }</option>
                          <option value="1">1 { window.t('course.month') }</option>
                          <option value="3">3 { window.t('course.few_months') }</option>
                          <option value="6">6 { window.t('course.months') }</option>
                          <option value="9">9 { window.t('course.months') }</option>
                          <option value="12">1 { window.t('course.year') }</option>
                          <option value="24">2 { window.t('course.years') }</option>
                          <option value="36">3 { window.t('course.years') }</option>
                        </Field>
                      </div>
                      <div className="form-group">
                        <label>{ window.t('course.descritpion') }</label>
                        <Editor
                          value={values.description}
                          apiKey="twiz0k7q1xl3d1v8ftfoe9dxtpdfxe9dq1figqsnb819zfgg"
                          init={{
                            height: 200,
                            menubar: false,
                            language: window.locale
                          }}
                          onEditorChange={this.handleEditorChange}
                        />
                      </div>
                      <div className="form-group">
                        <label>{ window.t('course.image') }</label>
                        <div className="row">
                          <div className="col-sm-6 col-md-3">
                            <figure className="pos-relative mg-b-0">
                              <img src={imageSrc || course.image_url} className="img-fit-cover" style={{maxWidth: '250px', height: 'auto'}} />
                            </figure>
                          </div>
                          <div className="col-sm-6 col-md-9">
                            <div className="mg-b-10">{ window.t('course.image_requirements') }</div>
                            <ImageCropper onUpdateImage={this.updateImageSrc} />
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <label>{ window.t('course.promo_video') }</label>
                        <div className="row">
                          <div className="col-sm-6 col-md-3">
                            <figure className="pos-relative mg-b-0">
                              { promoVideo && promoVideo.image_url 
                                ?
                                <a href={ Routes.tutor_video_path(promoVideo.content_id) } data-modal="true" >
                                  <img src={promoVideo.image_url} className="img-fit-cover" style={{width: '100%', height: 'auto'}} />
                                </a>
                                :
                                <img src={noimage} className="img-fit-cover" style={{width: '100%', height: 'auto'}} />
                              }  
                            </figure>
                          </div>
                          <div className="col-sm-6 col-md-9">
                            <div>{ window.t('course.promo_video_description') }</div>
                            <button className="btn btn-xs btn-white mg-t-10" type="button" onClick={() => this.openPromoVideoSelector()}>
                              { window.t('course.select_promo_video') }
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 text-right">
                          <button type="submit" className="btn btn-primary bnt-lg" disabled={isSubmitting || !isValid}>
                            { window.t('save') }
                          </button>
                        </div>
                      </div>  
                    </form>
                  )}
                </Formik>
                <GroupFilesTree 
                  ref="promoVideoSelector" 
                  target="coursePromoVideo" 
                  courseId={courseId}
                  onFileSelect={this.handlePromoVideoSelect}
                />
              </div>
            </div>
          </div>
        </div>
    )
  }
}
 
export default Details