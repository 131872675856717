import React from 'react'
import { client } from '../Client'
import CourseCreatorNav from './CourseCreatorNav'
import { Formik, Field } from 'formik'
import * as Yup from 'yup'
import { toastrNotification } from '../../helpers/Toastr'
  
class Publication extends React.Component {
  
  render() {
    const { locale, courseId } = this.props.match.params
    const course = this.props.course
    
    return (
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header">
                <CourseCreatorNav 
                  course={course} 
                  currentForm={'publication'}
                  locale={locale}
                />
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-12 offset-md-3 col-md-6">
                      <Formik
                        initialValues={{ status: course.status ? course.status.toString() : 'internal' }}
                        onSubmit={(values, { setSubmitting }) => {
                          client.updateCourse(course.id, values)
                          .then((course) => {
                            if (course.id) {
                              toastrNotification({ type: 'success', title: window.t('updated') })
                              this.props.onUpdateCourse(course)
                            } else {
                              toastrNotification({ type: 'error', title:  window.t('not_updated') })
                            }
                            setSubmitting(false)
                          })
                        }}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isValid,
                          isSubmitting,
                        }) => (
                          <form onSubmit={handleSubmit}>
                            <div className="form-row">
                              <div className="form-group col-md-12">
                                <label>{ window.t('course.select_publish_mode') }</label>
                                <div role="group" aria-labelledby="my-radio-group">
                                  <div className="custom-control custom-switch">
                                    <Field type="radio" name="status" value="internal" className="custom-control-input" id="statusInternal" />
                                    <label className="custom-control-label" htmlFor="statusInternal">{ window.t('course.internal') }</label>
                                  </div>
                                  <div className="mg-b-10">
                                    <small>{ window.t('course.internal_description') }</small>
                                  </div>
                                  <div className="custom-control custom-switch">
                                    <Field type="radio" name="status" value="for_sale" className="custom-control-input" id="statusForSale" />
                                    <label className="custom-control-label" htmlFor="statusForSale">{ window.t('course.for_sale') }</label>
                                  </div>
                                  <div className="mg-b-10">
                                    <small>{ window.t('course.for_sale_description') }</small>
                                  </div>
                                  { !course.ready_for_publication && values.status === 'for_sale' && 
                                    <div className="mg-b-10 mg-t-10 text-warning">
                                      { window.t('course.will_be_published_for_free') }
                                    </div>
                                  }  
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-12 text-right">
                                <button type="submit" className="btn btn-primary bnt-lg" disabled={isSubmitting || !isValid}>
                                  { window.t('save') }
                                </button>
                              </div>
                            </div>  
                          </form>
                        )}
                      </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    )
  }
}
 
export default Publication