import React from 'react'
import { Card, Button, Dropdown } from 'react-bootstrap'
import Swal from 'sweetalert2'
import { client } from '../../Client'
import CourseLectionForm from './CourseLectionForm'
import GroupFilesTree from '../../GroupFilesTree'
import LectionLinkForm  from './LectionLinkForm'
import LectionDocumentPreview from './LectionDocumentPreview'
import RecordingSelector from './RecordingSelector'
import { sortableHandle } from 'react-sortable-hoc'

const DragHandle = sortableHandle(() => (
  <span className="mg-r-10">
    <i className="fas fa-arrows-alt-v"></i>
  </span>
))

class CourseLection extends React.Component {

  state = {
    open: false
  }

  close = () => {
    this.setState({ open: false })
  }

  open = () => {
    this.setState({ open: true })
  }

  handleChevronClick = (event) => {
    event.preventDefault()
    this.setState({ open: !this.state.open })
  }

  showDeleteLectionConfirmation = (event, lection) => {
    event.preventDefault()
    Swal.fire({
      title: window.t('course.delete_lection_confirm'),
      text: lection.title,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: window.t('_yes'),
      cancelButtonText: window.t('_no')
    }).then((result) => {
      if (result.value) {
        const { courseId, sectionId } = this.props
        client.deleteCourseLection(courseId, sectionId, lection.id).
        then((result) => {
          this.props.onDeleteLection(sectionId, lection)
        })
      }
    })
  }

  deleteResource = (event, resource) => {
    event.preventDefault()
    const { lection, courseId, sectionId } = this.props
    client.deleteCourseLectionResource(courseId, sectionId, lection.id, resource.id)
    .then((lection) => {
      if (lection.id) {
        this.props.onUpdateLection(sectionId, lection)
      }
    })
  }

  deleteLink = (event, link) => {
    event.preventDefault()
    const { lection, courseId, sectionId } = this.props
    client.deleteCourseLectionLink(courseId, sectionId, lection.id, link.id)
    .then((lection) => {
      if (lection.id) {
        this.props.onUpdateLection(sectionId, lection)
      }
    })
  }

  renderLectionIcon = () => {
    const lection = this.props.lection
    if (lection.content.is_video) {
      return (<i className="far fa-play-circle"></i>)
    } else if (lection.content.is_survey) {
      return (<i className="far fa-check-square"></i>)
    } else if (lection.content.is_recording) {
      return (<i className="fas fa-video"></i>)
    } else {
      return (<i className="far fa-file"></i>)
    }
  }

  renderLectionContentLink = () => {
    const { lection, courseId, locale } = this.props
    if (lection.content.is_video) {
      return (<a href={Routes.tutor_video_path(locale, lection.content.video_id)} data-modal="true">{ lection.content.name }</a>)
    } else if (lection.content.is_survey) {
      return (<a href={Routes.tutor_course_survey_path(locale, courseId, lection.content.id)} >{ lection.content.name }</a>)
    } else if (lection.content.is_recording) {
      return (<a href={Routes.recording_path(locale, lection.content.id)} target="_blank">{ lection.content.name }</a>)
    } else {
      return (<LectionDocumentPreview lection={lection} />)
    }
  }

  render() {
    const { lection, courseId, sectionId, locale } = this.props,
          { open } = this.state,
          lectionHasContent = lection.content.id !== null

    const addContentBtn = (
      <Dropdown>
        <Dropdown.Toggle variant="white" size="xs" id="dropdown-basic">
          { lectionHasContent ? window.t('course.change_lection_content') : window.t('course.add_lection_content') }
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item as="button" onClick={() => this.refs.filesSelector.handleOpen()}>{ window.t('course.select_file') }</Dropdown.Item>
          <Dropdown.Item data-modal="true" href={Routes.new_tutor_course_survey_path(locale, courseId, {lection_id: lection.id})}>{ window.t('course.survey') }</Dropdown.Item>
          <RecordingSelector
            lection={lection}
            courseId={courseId}
            sectionId={sectionId}
            onRecordingSelect={this.props.onUpdateLection}
          />
        </Dropdown.Menu>
      </Dropdown>
    )

    return (
      <Card style={{marginBottom: '15px'}}>
        <Card.Header className="d-flex">
          <div>
            <DragHandle />
            <h6 className="d-inline mg-l-5 mg-r-10">
              { lection.title }
            </h6>
            <CourseLectionForm
              courseId={courseId}
              sectionId={sectionId}
              lection={lection}
              onUpdateLection={this.props.onUpdateLection}
              mode="edit"
            />
            { lection.editable &&
              <button className="btn btn-xs mg-r-5 btn-white" onClick={event => this.showDeleteLectionConfirmation(event, lection)} style={{padding: '3px 7px'}}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-trash"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path></svg>
              </button>
            }
          </div>

          <div className="ml-auto d-flex">
            { !lectionHasContent && lection.editable && addContentBtn }

            <a href="#" className="ml-3 text-body" onClick={this.handleChevronClick}>
              { open
                ?
                <span><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-up"><polyline points="18 15 12 9 6 15"></polyline></svg></span>
                :
                <span><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg></span>
              }
            </a>

            <GroupFilesTree
              ref="filesSelector"
              target="lection"
              courseId={courseId}
              sectionId={sectionId}
              lectionId={lection.id}
              onFileAdd={this.props.onUpdateLection}
            />
          </div>
        </Card.Header>
        { open &&
          <Card.Body>
            { lectionHasContent &&
              <div className="media">
                { this.renderLectionIcon() }
                <div className="media-body d-md-flex d-block mg-l-10">
                  { this.renderLectionContentLink() }
                  <div className="ml-auto">
                    { lection.editable &&
                      ( lection.content.is_survey
                        ?
                        <>
                          <a href={Routes.tutor_course_survey_path(locale, courseId, lection.content.id)} className="btn btn-white btn-xs mg-t-5 mg-sm-t-0">
                            <i className="far fa-edit"></i> { window.t('course.edit_survey') }
                          </a>
                          <a href={Routes.report_tutor_course_survey_path(locale, courseId, lection.content.id)} className="btn btn-white btn-xs mg-l-0 mg-sm-l-5 mg-t-5 mg-sm-t-0">
                            <i class="fas fa-table"></i> { window.t('course.survey_report') }
                          </a>
                        </>
                        : addContentBtn
                      )
                    }
                  </div>
                </div>
              </div>
            }

            { !lectionHasContent &&
              <div className="row">
                <div className="col-12 text-center">
                  { window.t('course.lection_content_hint') }
                </div>
              </div>
            }

            { lection.description &&
              <div>
                <hr/>
                <div className="row">
                  <div className="col-12">
                    { lection.description }
                  </div>
                </div>
              </div>
            }
            { lection.resources.length > 0 &&
              <div>
                <hr/>
                <h6>{ window.t('course.lection_resources') }</h6>
                { lection.resources.map(resource => (
                  <div className="row" key={`resource-${resource.id}`}>
                    <div className="col-12">
                      <div className="d-flex">
                        <div>
                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-download"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path><polyline points="7 10 12 15 17 10"></polyline><line x1="12" y1="15" x2="12" y2="3"></line></svg>
                          &#160;{ resource.name }
                        </div>
                        <div className="ml-auto">
                          <button className="btn btn-xs btn-white mg-b-5" onClick={(event) => this.deleteResource(event, resource)} style={{padding: '3px 7px'}}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-trash"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path></svg>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            }
            { lection.links.length > 0 &&
              <div>
                <hr/>
                <h6>{ window.t('course.lection_links') }</h6>
                { lection.links.map(link => (
                  <div className="row" key={`link-${link.id}`}>
                    <div className="col-12">
                      <div className="d-flex">
                        <div>
                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-external-link"><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path><polyline points="15 3 21 3 21 9"></polyline><line x1="10" y1="14" x2="21" y2="3"></line></svg>
                          &#160;<a href={link.url} target="_blank">{ link.name }</a>
                        </div>
                        <div className="ml-auto">
                          <button className="btn btn-xs btn-white mg-b-5" onClick={(event) => this.deleteLink(event, link)} style={{padding: '3px 7px'}}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-trash"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path></svg>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            }
            <div>
              <hr/>
              <button className="btn btn-xs btn-white mg-r-5 mg-b-5 mg-sm-b-0" onClick={() => this.refs.linkForm.open()}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-plus"><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>
                &#160;{ window.t('course.lection_add_link') }
              </button>
              <button className="btn btn-xs btn-white" onClick={() => this.refs.resourcesSelector.handleOpen()}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-plus"><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>
                &#160;{ window.t('course.lection_add_resource') }
              </button>
              <GroupFilesTree
                ref="resourcesSelector"
                target="lection_resource"
                courseId={courseId}
                sectionId={sectionId}
                lectionId={lection.id}
                onFileAdd={this.props.onUpdateLection}
              />
              <LectionLinkForm
                courseId={courseId}
                sectionId={sectionId}
                lection={lection}
                onUpdateLection={this.props.onUpdateLection}
                ref="linkForm"
              />
            </div>
          </Card.Body>
        }
      </Card>
    )
  }

}

export default CourseLection