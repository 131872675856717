const toastr = require("./../../../vendor/assets/javascripts/toastr.min.js")
toastr.options.timeOut = 8000
export function toastrNotification(opts) {
  if (opts.type === "success") {
    toastr.success(opts.message, opts.title)      
  } else if (opts.type === "info") {
    toastr.warning(opts.message, opts.title)            
  } else if (opts.type === "warning") {
    toastr.warning(opts.message, opts.title) 
  } else {
    toastr.error(opts.message, opts.title) 
  }
}
