import React from 'react'
import { client } from '../../Client'
import { Modal, ModalBody, ModalFooter, 
         ModalHeader, ModalTitle, Button } from 'react-bootstrap'
import { Formik } from 'formik'
import * as Yup from 'yup'

class LectionLinkForm extends React.Component {
  
  constructor(props) {
    super(props)
    
    this.state = {
      open: false
    }  
  }
  
  open = () => {
    this.setState({ open: true }) 
  }

  close = () => {
    this.setState({ open: false }) 
  }
  
  render() {
    
    const { mode, lection, sectionId, courseId } = this.props
    
    const validationSchema = Yup.object().shape({
      name: Yup.string().required(window.t('form.required')),
      url: Yup.string().url().required(window.t('form.required'))
    })
    
    return (
          <Modal show={this.state.open} onHide={this.close}>
            <Modal.Header closeButton>
              <Modal.Title>{ window.t('course.new_link') }</Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <Formik
                 initialValues={{ name: '', url: '' }}
                 validationSchema={validationSchema}
                 onSubmit={(values, { setSubmitting, resetForm }) => {
                   let data = values
                   client.addCourseLectionLink(courseId, sectionId, lection.id, values)
                   .then((lection) => {
                     if (lection.id) {
                       resetForm()
                       this.props.onUpdateLection(sectionId, lection)
                       this.close()
                     }
                     setSubmitting(false)
                   })
                 }}
               >
                 {({
                   values,
                   errors,
                   touched,
                   handleChange,
                   handleBlur,
                   handleSubmit,
                   isValid,
                   isSubmitting,
                 }) => (
                   <form onSubmit={handleSubmit}>
                     <div className="form-group">
                       <label>{ window.t('course.link_title') }</label>
                       <input
                         type="text"
                         name="name"
                         className={"form-control " + (errors.name && touched.name ? 'is-invalid' : '')}
                         onChange={handleChange}
                         onBlur={handleBlur}
                         value={values.name}
                         placeholder={ window.t('course.link_title_description') }
                       />
                       {errors.name && touched.name && 
                         <div className="invalid-feedback">{errors.name}</div>
                       }
                     </div>
                     <div className="form-group">
                       <label>URL</label>
                       <input
                         type="text"
                         name="url"
                         className={"form-control " + (errors.url && touched.url ? 'is-invalid' : '')}
                         onChange={handleChange}
                         onBlur={handleBlur}
                         value={values.url}
                         placeholder="https://example.com"
                       />
                       {errors.url && touched.url && 
                         <div className="invalid-feedback">{errors.url}</div>
                       }
                     </div>
                     <div className="row">
                       <div className="col-12 mg-t-25">
                          <div className="d-flex">
                            <a href="#" className="btn btn-link" onClick={() => this.close()}>{ window.t('cancel') }</a>
                            <Button type="submit" className="btn btn-primary ml-auto" disabled={isSubmitting || !isValid} >
                              { isSubmitting &&
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                              }
                              &#160;{ window.t('add') }
                            </Button>
                          </div>
                       </div>
                     </div>  
                   </form>
                 )}
               </Formik>
            </Modal.Body>
          </Modal>
      )  
  }
}
 
export default LectionLinkForm