import React from 'react'

const FinishCourseMessage = (props) => { 
  return (
    <div className="row">
      <div className="col-12">
        <div className="finish-course-message text-center">
          <div className="jumbotron">
            <h1 className="display-4">{ window.t('course_viewer.finish_course_title') }</h1>
            <p className="lead">{ window.t('course_viewer.finish_course_text') } `{props.course.title}`.</p>
          </div>
        </div>  
      </div>
    </div>
  )
}

export default FinishCourseMessage