import React from 'react'
import Details from './Details'
import Price from './Price'
import Curriculum from './Curriculum'
import Publication from './Publication'
import Users from './Users'
import CourseCreatorHeader from './CourseCreatorHeader'
import PackageItems from './PackageItems'
import { Route, Switch } from 'react-router-dom'

class CourseCreator extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      course: props.course,
      categories: props.categories
    }
  }

  updateCourse = (updatedCourse) => {
    this.setState({ course: updatedCourse })
  }

  render() {
    const { course, categories } = this.state,
          { locale } = this.props.match.params,
          pathname = this.props.location.pathname

    return (
      <div>
        <div>
          <CourseCreatorHeader course={course} locale={locale} pathname={pathname} />
        </div>

        <Switch>
          <Route
            exact
            path="/:locale/tutor/courses/:courseId"
            render={(props) => <Details {...props} course={course} categories={categories} onUpdateCourse={this.updateCourse} />}
          />
          <Route
            exact
            path="/:locale/tutor/courses/:courseId/curriculum"
            render={(props) => <Curriculum {...props} course={course} onUpdateCourse={this.updateCourse} />}
          />
          <Route
            exact
            path="/:locale/tutor/courses/:courseId/package_items"
            render={(props) => <PackageItems {...props} course={course} onUpdateCourse={this.updateCourse} />}
          />
          <Route
            exact
            path="/:locale/tutor/courses/:courseId/price"
            render={(props) => <Price {...props} course={course} onUpdateCourse={this.updateCourse} />}
          />
          <Route
            exact
            path="/:locale/tutor/courses/:courseId/publication"
            render={(props) => <Publication {...props} course={course} onUpdateCourse={this.updateCourse} />}
          />
          <Route
            exact
            path="/:locale/tutor/courses/:courseId/users"
            render={(props) => <Users {...props} course={course} />}
          />
        </Switch>
      </div>
    )
  }
}

export default CourseCreator