import React, { Component } from 'react'
import { client } from './../Client'
import ActionCable  from 'actioncable'
import UserAvatar from '../../helpers/UserAvatar'
import InfiniteScroll from 'react-infinite-scroll-component'

class Notifications extends Component {

  state = {
    unreadCount: 0,
    notifications: [],
    unreadMessages: [],
    unreadMessagesCount: 0,
    hasMoreUnreaMessages: true,
    unreadMessagesPage: 0
  }

  subscription = {}
  channelName = "NotificationsChannel"

  componentDidMount() {
    let _this = this

    this.getNotifications()
    this.getUnreadMessages()

    let listener = {
      received(data) {
        switch(data.action) {
        case 'new_notification':
          _this.addNewNotification(data)
          break
        case 'update_unread_notifications_size':
          _this.updateUnreadNotificationsSize(data)
          break
        case 'new_unread_message':
          _this.addNewUnreadMessage(data)
          break
        }
        //console.log("received, channel:" + _this.channelName, data)
      }
    }

    ActionCable.debugging = process.env.NODE_ENV === 'development'
    window.cable = ActionCable.createConsumer("/cable")
    this.subscription = window.cable.subscriptions.create({ channel: this.channelName }, listener)
  }

  componentWillUnmount() {
    if (this.subscription) {
      setTimeout(() => {console.log('unsubscribed'); this.subscription.unsubscribe()}, 100);
    }
  }

  getNotifications() {
    client.getNotifications().
    then((notifications) => {
      const unreadCount = notifications.filter(notification => !notification.is_read).length
      this.setState({ notifications: notifications, unreadCount: unreadCount })
    });
  }

  getUnreadMessages() {
    client.getUnreadMessages(this.state.unreadMessagesPage + 1).
    then((response) => {
      if ( response.messages.length > 0 ) {
        this.setState(Object.assign({},this.state, { unreadMessages: this.state.unreadMessages.concat(response.messages) }))
      }
      if (response.pagination) {
        this.setState(Object.assign({}, this.state,
          {
            unreadMessagesCount: response.pagination.total_count,
            unreadMessagesPage: response.pagination.current,
            hasMoreUnreaMessages: this.state.unreadMessages.length < response.pagination.total_count
          }
        ))
      } else {
        this.setState(Object.assign({}, this.state, { hasMoreUnreaMessages: false }))
      }
    })
  }

  addNewUnreadMessage(data) {
    const newMessage = JSON.parse(data.message)
    this.setState({ unreadMessages: [newMessage].concat(this.state.unreadMessages),
                    unreadMessagesCount: this.state.unreadMessagesCount + 1 })
  }

  addNewNotification(data) {
    const newNotification = JSON.parse(data.notification)
    this.setState({ notifications: [newNotification].concat(this.state.notifications),
                    unreadCount: this.state.unreadCount + 1 })
  }

  updateUnreadNotificationsSize(data) {
    this.setState({ unreadCount: data.unread_size })
  }

  render() {

    const { notifications, unreadMessages, unreadCount, unreadMessagesCount } = this.state

    return (
      <>
        <div className="dropdown dropdown-message">
          <a href="" className="dropdown-link new-indicator" data-toggle="dropdown">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-message-square"><path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path></svg>
            { unreadMessagesCount > 0 &&
              <span>{ unreadMessagesCount }</span>
            }
          </a>
          <div className="dropdown-menu dropdown-menu-right">
            <div className="dropdown-header" style={{fontSize: '10px'}}>
              { window.t("message.messages") }
            </div>
            { unreadMessages.length === 0 &&
              <p className="dropdown-item" >{ window.t("message.no_messages") }</p>
            }
            <div className="unread-messages-container" id="unreadMessagesContainer">
              <InfiniteScroll
                dataLength={this.state.unreadMessages.length}
                next={this.getUnreadMessages.bind(this)}
                hasMore={this.state.hasMoreUnreaMessages}
                loader={<div className="text-center spinner-border text-primary" role="status"><span className="sr-only">Loading...</span></div>}
                endMessage={<div></div>}
                height={this.state.unreadMessages.length > 0 ? '300px' : '50px'}
                scrollableTarget="#unreadMessagesContainer"
              >
                { unreadMessages.length > 0 && unreadMessages.map(message => (
                  <a href={ Routes.conversation_path(message.chat_conversation_id) } className="dropdown-item" key={message.id} >
                    <div className="media">
                      <UserAvatar user={message.user} />
                      <div className="media-body mg-l-15">
                        <strong>{ message.user.full_name }</strong>
                        { message.attachments.length > 0 &&
                          <p dangerouslySetInnerHTML={{__html: message.attachments[0].name}}></p>
                        }
                        { message.attachments.length === 0 &&
                          <p dangerouslySetInnerHTML={{__html: message.body}}></p>
                        }
                        <span>{ message.created_date }</span>
                      </div>
                    </div>
                  </a>
                ))
                }
              </InfiniteScroll>
            </div>
            <div className="dropdown-footer">
              <a href={ Routes.conversations_path() }>{ window.t("message.view_all_messages") }</a>
            </div>
          </div>
        </div>

        <div className="dropdown dropdown-notification">
          <a href="" className="dropdown-link new-indicator" data-toggle="dropdown">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-bell"><path d="M22 17H2a3 3 0 0 0 3-3V9a7 7 0 0 1 14 0v5a3 3 0 0 0 3 3zm-8.27 4a2 2 0 0 1-3.46 0"></path></svg>
            { this.state.unreadCount > 0 &&
              <span>{ this.state.unreadCount }</span>
            }
          </a>
          <div className="dropdown-menu dropdown-menu-right">
            <div className="dropdown-header" style={{fontSize: '10px'}}>
              <div className="d-flex justify-content-between">
                <span>{ window.t("notification.notifications") }</span>
                { notifications.filter(notif => !notif.is_read).length > 0 &&
                  <a href={Routes.mark_read_notifications_path()} data-method="post">{ window.t("notification.mark_all_read") }</a>
                }
              </div>
            </div>
            { notifications.length === 0 &&
              <p className="dropdown-item" >{ window.t("notification.no_notification") }</p>
            }
            { notifications.length > 0 &&
              <div style={{maxHeight: '300px', overflowY: 'auto'}}>
                {
                  notifications.map(notification => (
                    <a href={ Routes.notification_path(notification.id) } className={"dropdown-item notification " + (notification.is_read ? "" : "unread")} key={notification.id} data-modal="true">
                      <div className="media">
                        <div className="avatar avatar-sm">
                          <img src={ notification.avatar_url } className="rounded-circle" alt="" />
                        </div>
                        <div className="media-body mg-l-15">
                          <p>{ notification.message }</p>
                          <span>{ notification.date }</span>
                        </div>
                      </div>
                    </a>
                  ))
                }
              </div>
            }
            <div className="dropdown-footer">
              <a href={Routes.notifications_path()}>{ window.t("notification.view_all_notifications") }</a>
            </div>
          </div>
        </div>
      </>
    )
  }

}

export default Notifications
