export default class Question {
  static TYPES = Object.freeze({
    TEXT: 'short_answer',
    SINGLE: 'options_pick_one',
    MULTIPLE: 'options_pick_many',
  })
  
  static DEFAULTS = Object.freeze({
    text: window.t('survey.new_question_text'),
    type: Question.TYPES.TEXT,
    options: [],
    required: true,
    persisted: false
  })

  constructor(params = {}) {
    const { text, type, options, id, persisted, required } = { ...Question.DEFAULTS, ...params }
    this.text = text
    this.type = type
    this.options = options
    this.persisted = persisted
    this.required = required
    this.id = id || Math.random()
  }

  get hasOptions() {
    return (
      this.type === Question.TYPES.SINGLE ||
      this.type === Question.TYPES.MULTIPLE
    )
  }

  get inputType() {
    if (this.type === Question.TYPES.SINGLE) return "radio"
    if (this.type === Question.TYPES.MULTIPLE) return "checkbox"
    throw new Error("This question does not have an input type.")
  }
  
  merge(patch) {
    return new Question({ ...this, ...patch })
  }
}
