import React, { useState } from 'react'
import { client } from './../Client'
import Loader from '../../helpers/Loader'
import { Modal, Button } from 'react-bootstrap'
import { toastrNotification } from '../../helpers/Toastr'

const emptyContact = { value: '', 
                       type: 'email', 
                       error: '', 
                       sending: false, 
                       sent: false }

export default function InvitationModal({ groupId, companyId }) { 
  const [open, setOpen] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [completed, setCompleted] = useState(false)
  const [contacts, setContacts] = useState([emptyContact])
  
  const handleOpen = (e) => {
    e.preventDefault()
    setContacts([{...emptyContact}])
    setCompleted(false)
    setOpen(true)
  }
  
  const handleClose = (e) => {
    if (e) { e.preventDefault() }
    setOpen(false)
    if (completed) { Turbo.visit(window.location) }
  }
  
  const submit = () => {
    setSubmitting(true)
    setContactsSending()
    
    let newContacts = [...contacts]
    
    newContacts.forEach((contact, index) => {
      const invitationData = {
        group_id: groupId,
        company_id: companyId
      }
      
      if (contact.type === 'phone') {
        invitationData['phone'] = contact.value
      } else {
        invitationData['email'] = contact.value
      }
      
      client.createInvitation(invitationData)
      .then(response => {
        let error = ''
        if (!response.success) {
          error = response.error ? response.error : 'Unable to create invitation'
        }
        
        newContacts[index].sending = false
        newContacts[index].sent = true
        newContacts[index].error = error
        
        if (index === contacts.length - 1) {
          setSubmitting(false)
          setContacts(newContacts)
          setCompleted(true)
        }
      })
    })
  }
  
  const handleContactChange = (index, ev) => {
    let newContacts = contacts.map((contact, cIndex) => {
      const newContact = index === cIndex ? {...contact, value: ev.target.value} : contact
      return newContact
    })
    setContacts(newContacts)
  }
  
  const setContactsSending = () => {
    const newContacts = contacts.map(contact => {
      const newContact = {...contact, sending: true} 
      return newContact
    })
    setContacts(newContacts)
  }
    
  const addContactInput = (e, contactType) => {
    e.preventDefault()
    
    const newContact = {...emptyContact, type: contactType}
    
    setContacts([...contacts, newContact])
  }
  
  const deleteContactInput = (index) => {
    let newContacts = [...contacts]
    newContacts.splice(index, 1)
    setContacts(newContacts)
  }
  
  const renderContactButtonIcon = (contact) => {
    if (contact.sent) {
      if (contact.error.length > 0) {
        return (<i className="fas fa-exclamation text-danger"></i>)
      } else {
        return (<i className="fas fa-check text-success"></i>)
      }  
    } else {
      if (contact.sending) {
        return (
          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        )
      } else {
        return (<i className="fas fa-times"></i>)
      } 
    }
  }
    
  return (
    <>
      <a className="dropdown-item" href="#" onClick={handleOpen}>
        { window.t('invitation.invite_by_phone_or_email') }
      </a>  
      <Modal show={open} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="modal-title tx-16">
             { window.t('invitation.invite_by_phone_or_email') }
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-12">
              { contacts.map((contact, index) => (
                  <div key={'contact_'+index} className="mg-b-5">
                    <div className="d-flex">
                      { contact.type === 'phone'
                        ?
                        <input 
                          type="tel" 
                          name="contacts[]" 
                          id={'contact' + index} 
                          className={"form-control " + (contact.sent && contact.error.length > 0 ? 'is-invalid' : '') }
                          autoComplete="off" 
                          value={contact.value}
                          placeholder="+79123456789" 
                          onChange={ev => handleContactChange(index, ev)}
                        />
                        : 
                        <input 
                          type="email" 
                          name="contacts[]" 
                          id={'contact' + index} 
                          className={"form-control " + (contact.sent && contact.error.length > 0 ? 'is-invalid' : '') }
                          autoComplete="off" 
                          value={contact.value}
                          placeholder="user@example.com" 
                          onChange={ev => handleContactChange(index, ev)}
                        />
                      }
                      <button disabled={contact.sending || contact.sent} className="btn btn-default" onClick={() => deleteContactInput(index)}>
                        { renderContactButtonIcon(contact) }
                      </button>
                    </div>
                    { contact.sent && contact.error.length > 0 && 
                      <div className="invalid-feedback d-block mg-t-2">
                        { contact.error }
                      </div>
                    } 
                  </div>  
                ))
              }
            </div>
          </div>
          <div className="row">
            <div className="col-12 mg-t-10">
              {/*<a href="#" onClick={ev => addContactInput(ev, 'phone')} className="btn btn-white btn-xs">{ window.t('invitation.add_phone') }</a>*/}
              <a href="#" onClick={ev => addContactInput(ev, 'email')} className="btn btn-white btn-xs mg-l-5">{ window.t('invitation.add_email') }</a>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <a href="#" className="btn btn-link" onClick={handleClose}>{ window.t('cancel') }</a>
          { completed 
            ?
            <Button className="btn btn-primary ml-auto" onClick={handleClose}>
              { window.t('invitation.close') }
            </Button>
            :
            <Button className="btn btn-primary ml-auto" disabled={submitting} onClick={() => submit()}>
              { submitting &&
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              }
              &#160;{ window.t('invitation.invite') }
            </Button>
          }
        </Modal.Footer>
      </Modal>
    </>
  )
}
