import React from 'react'
import { Button, Overlay, Popover, OverlayTrigger } from 'react-bootstrap'

const popover = (preview, returnUrl) => {
  return (
    <Popover id="popover-basic">
      <Popover.Content>
        { preview
          ?
          <a href={returnUrl} className="text-body">
            <i className="fas fa-arrow-left"></i> {window.t('course_viewer.back_to_course')}
          </a>
          :
          <a href={returnUrl} className="text-body">
            <i className="fas fa-arrow-left"></i> {window.t('course_viewer.back_to_courses')}
          </a>
        }
      </Popover.Content>
    </Popover>
  )
}

const CourseTopMenuPopover = (props) => {
  const { returnUrl, preview } = props

  return (
    <OverlayTrigger trigger="click" placement="bottom" overlay={popover(preview, returnUrl)}>
      <Button variant="link">
        <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="css-i6dzq1"><circle cx="12" cy="12" r="1"></circle><circle cx="12" cy="5" r="1"></circle><circle cx="12" cy="19" r="1"></circle></svg>
      </Button>
    </OverlayTrigger>
  )
}

export default CourseTopMenuPopover