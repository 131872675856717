import React from 'react'
import ReactDOM from 'react-dom'
import InvitationModal from './InvitationModal'

document.addEventListener('turbo:load', () => {
  const invitationContainer = document.getElementById('invitationModal')
  
  if (invitationContainer) {
    const groupId = invitationContainer.dataset.group
    const companyId = invitationContainer.dataset.company
    
    ReactDOM.render(
      <InvitationModal 
        groupId={groupId}  
        companyId={companyId}
      />,
      invitationContainer,
    );
    
    //document.addEventListener('turbo:before-visit', () => {
    //  ReactDOM.unmountComponentAtNode(invitationContainer)
    //})
  }
    
})