import React from 'react'
import ReactDOM from 'react-dom'
import CourseCreator from './CourseCreator'
import { BrowserRouter as Router, Route } from 'react-router-dom'

document.addEventListener('turbo:load', () => {
  const creatorContainer = document.getElementById('courseCreator')
  
  if (creatorContainer) {
    const course = JSON.parse(creatorContainer.dataset.course),
          categories = JSON.parse(creatorContainer.dataset.categories)
          
    ReactDOM.render(
      <Router>
        <Route 
          path="/:locale/tutor/courses/:courseId"
          render={(props) => <CourseCreator {...props} course={course} categories={categories} />} 
        />
      </Router>,
      document.getElementById('courseCreator'),
    );
    
    //document.addEventListener('turbo:before-visit', () => {
    //  ReactDOM.unmountComponentAtNode(creatorContainer)
    //})  
  }
  
})