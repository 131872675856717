import React, { useState } from 'react'
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'

const DescriptionTab = (props) => {
  const { course, currentLection } = props

  return (
    <Accordion defaultActiveKey={currentLection.description ? '0' : '1'}>
      { currentLection.description && 
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="0" className="accordion-title ui-accordion-header" style={{padding: '12px 15px 12px 20px'}}>
            { window.t('course.lecture_review') }
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              <div dangerouslySetInnerHTML={{ __html: currentLection.description }}></div>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      }
      <Card>
        <Accordion.Toggle as={Card.Header} eventKey="1" className="accordion-title ui-accordion-header" style={{padding: '12px 15px 12px 20px'}}>
          { window.t('course.course_review') }
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="1">
          <Card.Body>
            { course.description ? 
              <div dangerouslySetInnerHTML={{ __html: course.description }}></div> : 
              <div>{ window.t('course.no_description') }</div>
            }
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      { (course.author.description || course.author.short_description) && 
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="2" className="accordion-title ui-accordion-header" style={{padding: '12px 15px 12px 20px'}}>
            { window.t('course.about_author') }
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="2">
            <Card.Body>
              <div className="row">
                  <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    <img src={course.author.image_url} alt={course.author.name} width='100%' height='auto' />
                  </div>
                  <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12 mg-t-15 mg-sm-t-0">
                      <h5>{ course.author.full_name }</h5>
                      <hr />
                      <div dangerouslySetInnerHTML={{ __html: course.author.short_description  }}></div>
                  </div>
              </div>

              { course.author.description &&
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mg-t-20">
                    <div dangerouslySetInnerHTML={{ __html: course.author.description  }}></div>
                  </div>
                </div>
              } 
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      }
    </Accordion>
  )
}

export default DescriptionTab