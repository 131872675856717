import React from 'react'
import ReactDOM from 'react-dom'
import SubmissionFilesUploader from './SubmissionFilesUploader'

document.addEventListener('turbo:load', () => {
  const submissionFilesUploaderBtnContainer = document.getElementById('submissionFilesUploaderBtn')
  
  if (submissionFilesUploaderBtnContainer) {
    ReactDOM.render(
      <SubmissionFilesUploader />,
      submissionFilesUploaderBtnContainer,
    );
    
    //document.addEventListener('turbo:before-visit', () => {
    //  ReactDOM.unmountComponentAtNode(submissionFilesUploaderBtnContainer)
    //})
  }
    
})