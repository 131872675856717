import React from 'react'
import { client } from '../Client'
import CourseTopMenuPopover from './CourseTopMenuPopover'
import CourseSection from './CourseSection'
import CourseTabs from './CourseTabs'
import VideoPlayer from './VideoPlayer'
import ContentDocument from './ContentDocument'
import ContentSurvey from './ContentSurvey'
import ContentRecording from './ContentRecording'
import ContentEmpty from './ContentEmpty'
import ContentError from './ContentError'
import FinishCourseMessage from './FinishCourseMessage'

import { CircularProgressbar } from 'react-circular-progressbar'

import 'react-circular-progressbar/dist/styles.css'

class CourseViewer extends React.Component {

  constructor(props) {
    super(props)
    const lectionId = this.props.match.params.lectionId,
          lection = this.props.course.lections.find(l => l.id == lectionId)

    this.state = {
      course: props.course,
      trackers: props.trackers,
      currentLection: lection,
      prevLection: null,
      nextLection: null,
      fullWidth: false,
      progress: this.calculateProgress(props.trackers, props.course.lections),
      finishCourseMessageIsActive: false
    }
  }

  componentDidMount() {
    this.setState({ prevLection: this.findPrevLection(),
                    nextLection: this.findNextLection() })
  }

  calculateProgress(trackers, lections) {
    if (lections.length == 0) { return 0 }
    if (this.props.preview) { return 0 }
    return Math.round(trackers.filter(tr => tr.completed_at !== null).length / lections.length * 100)
  }

  updateCourse = (updatedCourse) => {
    this.setState({ course: updatedCourse })
  }

  toggleScreenWidth(event) {
    event.preventDefault()
    this.setState({ fullWidth: !this.state.fullWidth })
  }

  loadLection = (event, lection) => {
    event.preventDefault()

    const { course, trackers } = this.state,
          locale = this.props.match.params.locale,
          license = this.props.license

    const url = this.props.preview ?
                Routes.tutor_course_lection_path(course.slug, lection.id, {locale: locale}) :
                Routes.pupil_course_lection_path(course.slug, lection.id, {locale: locale})

    this.setState({ currentLection: lection, finishCourseMessageIsActive: false }, () => {
      window.history.pushState({}, lection.title, url)
      window.scrollTo(0, 0)
      this.setState({ prevLection: this.findPrevLection(),
                      nextLection: this.findNextLection() })

      if (!this.props.preview) {
        client.markLectionStarted(course.id, lection.id, license.id)
        .then(tracker => {
          if (tracker.id) {
            let newTrackers = []
            if (trackers.findIndex(tr => tr.id === tracker.id) !== -1) {
              newTrackers = trackers.map(tr => {
                if (tr.id === tracker.id) {
                  return tracker
                } else {
                  return tr
                }
              })
            } else {
              newTrackers = [...trackers, tracker]
            }

            this.setState({ trackers: newTrackers,
                            progress: this.calculateProgress(newTrackers, course.lections) })
          }
        })
      }
    })
  }

  markLectionCompleted = (lection) => {
    if (this.props.preview) { return }

    const trackers = this.state.trackers,
          license = this.props.license

    //return if lection is already completed
    const lectionTracker = trackers.find(tr => tr.lection_id === lection.id)
    if (lectionTracker && lectionTracker.completed_at !== null) {
      return
    }

    client.markLectionCompleted(this.state.course.id, lection.id, license.id)
    .then((tracker) => {
      if (tracker.id) {
        const newTrackers = this.state.trackers.map((tr) => {
          if (tr.id === tracker.id) {
            return tracker
          } else {
            return tr
          }
        })
        this.setState({ trackers: newTrackers,
                        progress: this.calculateProgress(newTrackers, this.state.course.lections) })
      }
    })
  }

  findPrevLection() {
    const { currentLection, course } = this.state
    const currentIndex = course.lections.findIndex(l => l.id === currentLection.id)
    return currentIndex === 0 ? null : course.lections[currentIndex - 1]
  }

  findNextLection() {
    const { currentLection, course } = this.state
    const currentIndex = course.lections.findIndex(l => l.id === currentLection.id)
    return currentIndex === course.lections.length - 1 ? null : course.lections[currentIndex + 1]
  }

  showFinishCourseMessage = (event) => {
    const course = this.state.course
    event.preventDefault()
    this.setState({ finishCourseMessageIsActive: true,
                    nextLection: null,
                    prevLection: course.lections[course.lections.length - 1] }, () => {
      window.scrollTo(0, 0)
    })
  }

  renderLectionContent() {
    const lection = this.state.currentLection
    if (this.state.finishCourseMessageIsActive) {
      return (<FinishCourseMessage course={this.state.course} />)
    } else if (!lection.content.id) {
      return (<ContentEmpty />)
    } else if (lection.content.is_video) {
      return (<VideoPlayer
                lection={lection}
                markLectionCompleted={this.markLectionCompleted}
              />)
    } else if (lection.content.is_survey) {
      return (<ContentSurvey
                lection={lection}
                markLectionCompleted={this.markLectionCompleted}
                preview={this.props.preview}
                license={this.props.license}
              />)
    } else if (lection.content.is_recording) {
      return (<ContentRecording lection={lection} />)
    } else {
      return (<ContentDocument lection={lection} />)
    }
  }

  render() {
    const { course,
            currentLection,
            fullWidth,
            trackers,
            progress,
            prevLection,
            nextLection,
            finishCourseMessageIsActive } = this.state

    const { locale } = this.props.match.params

    const lectionsSectionClass = fullWidth ?
                                 'd-none lections-section' :
                                 'col-3 d-none d-sm-block lections-section position-fixed'

    const courseContentClass = fullWidth ?
                               'col-12 course-content' :
                               'col-12 col-md-9 offset-md-3 course-content'

    return (
      <div className="row">
        <div className={lectionsSectionClass}>
          <div className="lections-section-header">
            <h5>{ window.t('course_viewer.table_of_content') }</h5>
          </div>
          <div className="lections-section-body">
            { course.sections.map((section, index) => (
              <CourseSection
                section={section}
                course={course}
                index={index}
                locale={locale}
                currentLection={currentLection}
                loadLection={this.loadLection}
                trackers={trackers}
                isOpened={section.lections.findIndex(l => l.id === currentLection.id) !== -1}
                showFinishCourseLink={index === course.sections.length - 1 && progress === 100}
                showFinishCourseMessage={this.showFinishCourseMessage}
                finishCourseMessageIsActive={finishCourseMessageIsActive}
                key={'section' + section.id}
              />
            ))}
          </div>
        </div>
        <div className={courseContentClass}>
          <div className="course-content-header d-flex justify-content-between align-items-baseline">
            <a href="#" onClick={this.toggleScreenWidth.bind(this)} className="btn btn-default d-none d-sm-block">
              { fullWidth
                ?
                <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="css-i6dzq1"><line x1="3" y1="12" x2="21" y2="12"></line><line x1="3" y1="6" x2="21" y2="6"></line><line x1="3" y1="18" x2="21" y2="18"></line></svg>
                :
                <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="css-i6dzq1"><polyline points="15 18 9 12 15 6"></polyline></svg>
              }
            </a>
            <h6 className="ml-3 ml-sm-0">{ course.title }</h6>
            <div>
              <span>
                <CircularProgressbar
                  value={progress}
                  text={`${progress}%`}
                  className='course-progress'
                />
              </span>
              <CourseTopMenuPopover
                preview={this.props.preview}
                returnUrl={this.props.returnUrl}
              />
            </div>
          </div>
          <div className="course-content-body">
            { this.renderLectionContent() }
            <div className="row">
              <div className="col-12 mg-t-30">
                <div className="d-flex justify-content-center">
                  { prevLection &&
                    <a className="btn btn-xs btn-white mg-r-5" onClick={(event) => this.loadLection(event, prevLection)} >
                      <i className="fas fa-chevron-left"></i> {prevLection.title}
                    </a>
                  }
                  { nextLection &&
                    <a className="btn btn-xs btn-white mg-l-5" href="#" onClick={(event) => this.loadLection(event, nextLection)} >
                      {nextLection.title} <i className="fas fa-chevron-right"></i>
                    </a>
                  }
                  { nextLection === null && progress === 100 && !finishCourseMessageIsActive &&
                    <a className="btn btn-xs btn-white mg-l-5" href="#" onClick={(event) => this.showFinishCourseMessage(event)} >
                      { window.t('course_viewer.finish_course') } <i className="fas fa-chevron-right"></i>
                    </a>
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="course-content-footer">
            <div className="row">
              <div className="col-12 course-tabs px-20">
                { course &&
                  <CourseTabs
                    course={course}
                    locale={locale}
                    currentLection={currentLection}
                    fullWidth={fullWidth}
                    loadLection={this.loadLection}
                    trackers={trackers}
                    showFinishCourseMessage={this.showFinishCourseMessage}
                    finishCourseMessageIsActive={finishCourseMessageIsActive}
                    progress={progress}
                  />
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default CourseViewer