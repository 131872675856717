import React, { useState, useEffect } from 'react'
import { client } from './../Client'
import Loader from '../../helpers/Loader'
import { Modal, Button } from 'react-bootstrap'
import { toastrNotification } from '../../helpers/Toastr'

export default function PackageItemsModal({ course }) {
  const [open, setOpen] = useState(false)
  const [checked, setChecked] = useState([])
  const [courses, setCourses] = useState([])
  const [search, setSearch] = useState('')
  const [loading, setLoading] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const [updateLicenses, setUpdateLicenses] = useState(false)

  useEffect(() => {
    setLoading(true)
    client.getCourses().then(items => {
      const fetchedCourses = items.filter(item => !item.package)
      setCourses(fetchedCourses)
      setLoading(false)
    })
  }, [])

  useEffect(() => {
    const filteredCourses = courses.map(course => {
      if (course.title.toLowerCase().includes(search.toLowerCase())) {
        course.hidden = false
      } else {
        course.hidden = true
      }
      return course
    })
    setCourses(filteredCourses)
  }, [search])

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleCheck = (courseId) => {
    if (checked.includes(courseId)) {
      setChecked(checked.filter(ch => ch !== courseId))
    } else {
      setChecked([...checked, courseId])
    }
  }

  const handleSearch = (ev) => {
    setSearch(ev.target.value)
  }

  const handleUpdateLicensesClick = () => {
    setUpdateLicenses(!updateLicenses)
  }

  const submit = () => {
    if (checked.length === 0) {
      toastrNotification({ type: 'warning', title: window.t('course.you_need_to_select_courses') })
      return
    }

    setDisabled(true)
    client.createCoursePackageItems(course.id, checked, updateLicenses).then(response => {
      if (response.success) {
        setDisabled(false)
        setOpen(false)
        Turbo.visit(window.location)
      } else {
        setDisabled(false)
        toastrNotification({ type: 'error', title: 'Unable to add courses' })
      }
    })
  }

  return (
    <>
      <Button variant="white" className="btn btn-sm btn-white" onClick={handleOpen}>
        <i className="fas fa-plus icon" />
        <span className="mg-l-5">
          { window.t("course.add_courses") }
        </span>
      </Button>
      <Modal show={open} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="modal-title tx-16">
            { window.t('course.select_package_courses') }
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          { loading && <Loader /> }

          { !loading && courses.length > 0 &&
            <div>
              <div className="search-form mg-b-15">
                <input type="search" className="form-control" placeholder={window.t('search')} onChange={handleSearch} />
                <button className="btn" disabled type="button">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
                </button>
              </div>

              <div style={{maxHeight: "250px", overflowY: "auto"}}>
                {
                  courses.filter(course => !course.hidden).map(course => (
                    <div className="custom-control custom-checkbox mg-b-5" key={"course_"+course.id}>
                      <input type="checkbox" checked={checked.includes(course.id)} className="custom-control-input" id={"course" + course.id} onChange={() => handleCheck(course.id)} />
                      <label className="custom-control-label" htmlFor={"course" + course.id}>{course.title}</label>
                    </div>
                  ))
                }
              </div>

              <hr/>

              <div className="custom-control custom-checkbox mg-t-15">
                <input type="checkbox" checked={updateLicenses} className="custom-control-input" id="updateLicenses" onChange={handleUpdateLicensesClick} />
                <label className="custom-control-label" htmlFor="updateLicenses">{ window.t('course.update_course_licenses') }</label>
              </div>
            </div>
          }

          { !loading && courses.length == 0 &&
            <div className="text-center">
              { window.t('course.no_available_courses_for_package') }
            </div>
          }

        </Modal.Body>
        <Modal.Footer>
          <Button variant="link" onClick={handleClose}>{ window.t('cancel') }</Button>
          <Button className="btn btn-primary ml-auto" disabled={disabled} onClick={submit} >
            { disabled &&
              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            }
            &#160;{ window.t('add') }
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}